<template>
  <div>
  <v-row>
    <v-col md="12" lg="12" xl="12" cols="12" v-if="dialog==true">
      <v-dialog
          v-model="dialog"
          persistent
          max-width="500"
          >
          <v-card>
              <v-card-title class="text-h5">
              Validation de l'offre
              </v-card-title>
              <v-card-text>
                  <v-alert v-if="error==true && loading==false" type="error">
                      {{messageDialoge}}
                  </v-alert>
                  <v-alert v-if="error==false && loading==false" type="success">
                      {{messageDialoge}}
                  </v-alert>
                  <v-flex  class="text-center">
                      <v-progress-circular v-if="loading==true"
                          :size="150"
                          :width="7"
                          color="primary"
                          indeterminate
                          >
                          Patientez  ...
                      </v-progress-circular>
                  </v-flex>
              </v-card-text>
              <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn v-if="loading==false"
                  color="green darken-1"
                  text
                  @click="dialog = false"
              >
                  FERMER
              </v-btn>
              </v-card-actions>
          </v-card>
      </v-dialog>
  </v-col>
  </v-row>
 <TestView class="text-right pa-6" :parametreCompteur="parametreCompteur" :donnerDesFournisseur="Propositions" :nbrMonthsEnedis="enedisData['mesuresInfo'][0]['nbrMonth']" 
            :dataEnedis="enedisData" :numCompteur="numCompteur" :turpeData="turpeData"
            :indexes="selected" :typeCompteur="typeCompteur" :fournisseurActuelle="fournisseurActuelle"/>
 <v-expansion-panels v-model="panel">
    <!-- v-for="(item,i) in 5" :key="i" -->
    <v-list-item
        style="background: rgba(255, 255, 255, 0.1);"
        class="pa-2 mr-6"
        v-for="(company, index) in Propositions"
        :key="company.name"
      >
        <v-checkbox v-if="fournisseurActuelle == true && index == 0"
          class="mr-2"
          input-value="true"
          :disabled="true"
        ></v-checkbox>
        <v-checkbox v-else
          class="mr-2"
          v-model="selected"
          @click.capture.stop
          :value="index"
          :disabled="(selected.length > 4 && selected.indexOf(index) === -1) || (selected.length > 3 && selected.indexOf(index) === -1 && fournisseurActuelle == true)"
        ></v-checkbox>
        <v-expansion-panel class="">
          <v-expansion-panel-header >
            <v-row outlined no-gutters class="header" 
              style="background: #ffffff;">
              <v-col cols="12" sm="4" md="3" lg="3" xl="3">
                <div>
                  <v-img
                    overflow="hiden"
                    class="align-self-center"
                    max-width="238px"
                    max-height="80px"
                    contain
                    :src="getImageLogo(company.logo)"
                  >
                  </v-img>
                </div>
              </v-col>
              <v-col cols="12" sm="4" md="3" lg="3" xl="3">
                <div>
                  <p class="text-body-1 grey--text  mb-2">Offre: <span class="text-h6 black--text font-weight-regular">{{ company.type }}</span></p>
                  <p class="text-body-1 grey--text  mb-2">Date debut: <span class="black--text font-weight-regular">{{ company.dateDebut }}</span></p>
                  <p class="text-body-1 grey--text  mb-2">Date fin: <span class="black--text font-weight-regular">{{ company.dateFin }}</span></p>
                </div>
              </v-col>
              <!-- Budget -->
              <v-col cols="12" sm="4" md="3" lg="3" xl="3" class="d-flex flex-column">
                <div>
                  <p class="text-body-1 grey--text  mb-2">Budget:</p>
                  <p class="text-h5 font-weight-regular ">
                    {{ company.budget.toFixed(2) }} €/an
                  </p>
                </div>
              </v-col>

              <!-- Bouton Souscrire -->
              <v-col class="pt-3 d-flex"  cols="12" sm="12" md="3" lg="3" xl="3">
                <v-row>
                  <v-col v-if="company.fournisseur != 'Primeo' && company.fournisseur != 'engie' &&
                   company.fournisseur != 'Gazel Energie' && company.fournisseur != 'totale energie' && company.fournisseur != 'EDS'" cols="6" sm="6" md="6" lg="6" xl="6">
                    <v-btn depressed class="ma-auto text-capitalize" color="primary">
                      Éditer contrat</v-btn>
                  </v-col>
                  <v-col v-if="company.fournisseur == 'Primeo'" cols="6" sm="6" md="6" lg="6" xl="6">
                      <premioContrat :donner="company" :numCompteur="numCompteur" />
                  </v-col>
                  
                  <v-col v-if="company.fournisseur == 'engie'  || company.fournisseur == 'Engie'" cols="6" sm="6" md="6" lg="6" xl="6">
                      <engiePro :donner="company" :numCompteur="numCompteur" :typeCompteur="typeCompteur" />
                  </v-col>
                  <v-col v-if="company.fournisseur == 'Gazel Energie'" cols="6" sm="6" md="6" lg="6" xl="6">
                      <gazelContrat :donner="company" :numCompteur="numCompteur" :offre="company.type" />
                  </v-col>
                  <v-col v-if="company.fournisseur == 'totale energie' && company.type != 'HORIZON'" cols="6" sm="6" md="6" lg="6" xl="6">
                      <Totale :donner="company" :numCompteur="numCompteur" />
                  </v-col>
                  
                  <v-col v-if="company.fournisseur == 'totale energie' && company.type == 'HORIZON'" cols="6" sm="6" md="6" lg="6" xl="6">
                      <TotalHorizon :donner="company" :numCompteur="numCompteur" />
                  </v-col>
                  <v-col v-if="company.fournisseur == 'EDS' && company.typeFournisseur != 'AuFrn' && company.typeFournisseur != 'Actuelle'" cols="6" sm="6" md="6" lg="6" xl="6">
                      <edsContrat :donner="company" :numCompteur="numCompteur" />
                  </v-col>
                  
                  <v-col cols="6" sm="6" md="6" lg="6" xl="6">
                    <v-btn v-if="company.fournisseur == 'Primeo'" color="cyan" @click="validOffre(company.offerId, company.transactionId,
                      company.demandeur, company.firstName,  company.lastName,  company.phone,  company.email, numCompteur)">
                       Valider l'offre
                    </v-btn>
                  </v-col>
                </v-row>
              </v-col>
              <v-col style="background: #ffffff;" cols="12" sm="12"  md="12" lg="12"  xl="12" class=" px-4 pt-4 header d-flex flex-column"
                :id="index" v-if="index != panel">
                <v-row  style="background: #ffffff;">
                  <v-col
                    cols="12"
                    sm="6"
                    md="3"
                    lg="3"
                    xl="3"
                    class="d-flex flex-column"
                  >
                    <v-card class="pl-4 pr-3 pb-2 pt-4">
                      <p class="text-body-1 blue--text  mb-3">
                        <span class="text-body-1 grey--text  mb-1"
                          >Prix du KWh: </span
                        >{{ company.budgetKWH.toFixed(2) }} €
                      </p>
                    </v-card>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="6"
                    md="3"
                    lg="3"
                    xl="3"
                    class="d-flex flex-column"
                  >
                    <v-card class="pl-4 pr-3 pb-2 pt-4">
                      <p class="text-body-1 blue--text  mb-3">
                        <span class="text-body-1 grey--text  mb-1">CAPA: </span>
                        {{ company.budgetCapa.toFixed(2)==0 ? "Inclus" :  company.budgetCapa.toFixed(2)+"€"}}
                      </p>
                    </v-card>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="6"
                    md="3"
                    lg="3"
                    xl="3"
                    class="d-flex flex-column"
                  >
                    <v-card class="pl-4 pr-3 pb-2 pt-4">
                      <p class="text-body-1 blue--text  mb-3">
                        <span class="text-body-1 grey--text  mb-1">TURPE: </span>
                        <span v-if="company.TURPE == 'inclus'">Inclus</span>
                        <span v-else>{{ parseFloat(company.TURPE).toFixed(2) }} €</span>
                      </p>
                    </v-card>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="6"
                    md="3"
                    lg="3"
                    xl="3"
                    class="d-flex flex-column"
                  >
                    <v-card class="pl-4 pr-3 pb-2 pt-4">
                      <p class="text-body-1 blue--text  mb-3">
                        <span class="text-body-1 grey--text  mb-1">TAXE: </span>
                        {{ company.budgetTaxe.toFixed(2) }} €
                      </p>
                    </v-card>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-card >
              <v-row outlined no-gutters class="px-4 pt-4 header" style="background: #ffffff;">
                <!-- Prix du KWh -->
                
                
                <v-col
                  cols="12"
                  sm="6"
                  md="3"
                  lg="3"
                  xl="3"
                  class="px-4 pt-4 d-flex flex-column"
                >
                  <v-card class="pl-4 pr-3 pb-2 pt-4">
                    <p class="text-body-1 blue--text  mb-3">
                      <span class="text-body-1 grey--text  mb-1">Prix du KWh: </span>
                      {{ company.budgetKWH.toFixed(2) }} €
                    </p>
                  </v-card>
                  <v-container class="pl-4 pr-3 pb-2 pt-4">
                    <p v-if="typeCompteur == 'c3'">
                      <span class="text-body-2 grey--text  mb-1">POINTE: </span>
                      {{ parseFloat(company.unitairepointe).toFixed(2) }} €/Mwh
                    </p>
                    <p v-if="typeCompteur == 'c5hp'">
                      <span class="text-body-2 grey--text  mb-1">HP: </span>
                      {{ parseFloat(company.unitairehp).toFixed(2) }} €/Mwh
                    </p>
                    <p v-if="typeCompteur == 'c5hp'">
                      <span class="text-body-2 grey--text  mb-1">HC: </span>
                      {{ parseFloat(company.unitairehc).toFixed(2) }} €/Mwh
                    </p>
                    <p v-if="typeCompteur == 'c5base'">
                      <span class="text-body-2 grey--text  mb-1">BASE: </span>
                      {{ parseFloat(company.unitaire).toFixed(2) }} €/Mwh
                    </p>
                    <p v-if="typeCompteur == 'c3' || typeCompteur == 'c4'">
                      <span class="text-body-2 grey--text  mb-1">HPH: </span>
                      {{ parseFloat(company.unitairehph).toFixed(2) }} €/Mwh
                    </p>
                    <p v-if="typeCompteur == 'c3' || typeCompteur == 'c4'">
                      <span class="text-body-2 grey--text  mb-1">HCH: </span>
                      {{ parseFloat(company.unitairehch).toFixed(2) }} €/Mwh
                    </p>
                    <p v-if="typeCompteur == 'c3' || typeCompteur == 'c4'">
                      <span class="text-body-2 grey--text  mb-1">HPE: </span>
                      {{ parseFloat(company.unitairehpe).toFixed(2) }} €/Mwh
                    </p>
                    <p v-if="typeCompteur == 'c3' || typeCompteur == 'c4'">
                      <span class="text-body-2 grey--text  mb-1">HCE: </span>
                      {{ parseFloat(company.unitairehce).toFixed(2) }} €/Mwh
                    </p>
                    <p>
                      <span class="text-body-2 grey--text  mb-1">ABO: </span>
                      {{ parseFloat(company.mensuel).toFixed(2) }} €/Mois
                    </p>
                    
                    <p>
                      <span class="text-body-2 grey--text  mb-1">CEE: </span>
                      <span v-if="company.cee == 'NON SOUMIS'">NON SOUMIS</span>
                      <span v-else>{{ parseFloat(company.cee).toFixed(2) }} Mwh</span>
                    </p>
                  </v-container>
                </v-col>

                <!-- CAPA -->
                <v-col
                  cols="12"
                  sm="6"
                  md="3"
                  lg="3"
                  xl="3"
                  class="px-4 pt-4 d-flex flex-column"
                >
                  <v-card class="pl-4 pr-3 pb-2 pt-4">
                    <p class="text-body-1 blue--text  mb-3">
                      <span class="text-body-1 grey--text  mb-1">CAPA: </span>
                        {{ company.budgetCapa.toFixed(2)==0 ? "Inclus" :  company.budgetCapa.toFixed(2)+"€"}}
                    </p>
                  </v-card>
                  <v-container class="pl-4 pr-3 pb-2 pt-4">
                    <p v-if="typeCompteur == 'c3'">
                      <span class="text-body-2 grey--text  mb-1">POINTE: </span>
                      <span v-if="company.capa_pointe == 'inclus'">Inclus</span>
                      <span v-else
                        >{{company.budgetCapa.toFixed(2)==0 ? "Inclus" : parseFloat(company.capa_pointe).toFixed(2)+"€/Mwh"}} </span
                      >
                    </p>
                    <p v-if="typeCompteur == 'c5hp'">
                      <span class="text-body-2 grey--text  mb-1">HP: </span>
                      <span v-if="company.capa_hp == 'inclus'">Inclus</span>
                      <span v-else
                        >{{ company.budgetCapa.toFixed(2)==0 ? "Inclus" : parseFloat(company.capa_hp).toFixed(2) +"€/Mwh" }}</span
                      >
                    </p>
                    <p v-if="typeCompteur == 'c5hp'">
                      <span class="text-body-2 grey--text  mb-1">HC: </span>
                      <span v-if="company.capa_hc == 'inclus'">Inclus</span>
                      <span v-else
                        >{{ company.budgetCapa.toFixed(2)==0 ? "Inclus" : parseFloat(company.capa_hc).toFixed(2)+ "€/Mwh"}}</span
                      >
                    </p>
                    <p v-if="typeCompteur == 'c5base'">
                      <span class="text-body-2 grey--text  mb-1">BASE: </span>
                      <span v-if="company.capa_base == 'inclus'">Inclus</span>
                      <span v-else
                        >{{ company.budgetCapa.toFixed(2)==0 ? "Inclus" : parseFloat(company.capa_base).toFixed(2)+"€/Mwh"}}</span
                      >
                    </p>
                    <p v-if="typeCompteur == 'c3' || typeCompteur == 'c4'">
                      <span class="text-body-2 grey--text  mb-1">HPH: </span>
                      <span v-if="company.capa_hph == 'inclus'">Inclus</span>
                      <span v-else
                        >{{company.budgetCapa.toFixed(2)==0 ? "Inclus" : parseFloat(company.capa_hph).toFixed(2) +"€/Mwh"}}</span
                      >
                    </p>
                    <p v-if="typeCompteur == 'c3' || typeCompteur == 'c4'">
                      <span class="text-body-2 grey--text  mb-1">HCH: </span>
                      <span v-if="company.capa_hch == 'inclus'">Inclus</span>
                      <span v-else
                        >{{company.budgetCapa.toFixed(2)==0 ? "Inclus" : parseFloat(company.capa_hch).toFixed(2) +"€/Mwh"}}</span
                      >
                    </p>
                    <p v-if="typeCompteur == 'c3' || typeCompteur == 'c4'">
                      <span class="text-body-2 grey--text  mb-1">HPE: </span>
                      <span v-if="company.capa_hpe == 'inclus'">Inclus</span>
                      <span v-else
                        >{{ company.budgetCapa.toFixed(2)==0 ? "Inclus" : parseFloat(company.capa_hpe).toFixed(2) +"€/Mwh"}}</span
                      >
                    </p>
                    <p v-if="typeCompteur == 'c3' || typeCompteur == 'c4'">
                      <span class="text-body-2 grey--text  mb-1">HCE: </span>
                      <span v-if="company.capa_hce == 'inclus'">Inclus</span>
                      <span v-else
                        >{{company.budgetCapa.toFixed(2)==0 ? "Inclus" : parseFloat(company.capa_hce).toFixed(2)+"€/Mwh"}}</span
                      >
                    </p>
                  </v-container>
                </v-col>

                <!-- TURP -->
                <v-col
                  cols="12"
                  sm="6"
                  md="3"
                  lg="3"
                  xl="3"
                  class="px-4 pt-4 d-flex flex-column"
                >
                  <v-card class="pl-4 pr-3 pb-2 pt-4">
                    <p class="text-body-1 blue--text  mb-3">
                      <span class="text-body-1 grey--text  mb-1">TURPE: </span>
                        <span v-if="company.TURPE == 'inclus'">Inclus</span>
                        <span v-else>{{ parseFloat(company.TURPE).toFixed(2) }} €</span>
                    </p>
                  </v-card>
                  <v-container class="pl-4 pr-3 pb-2 pt-4">
                    <p>
                      <span class="text-body-2 grey--text  mb-1"
                        >C.A de comptage:
                      </span>
                      <span v-if="company.CACC == 'inclus'">Inclus</span>
                      <span v-else>{{ parseFloat(company.CACC).toFixed(2) }} €</span>
                    </p>
                    <p>
                      <span class="text-body-2 grey--text  mb-1"
                        >C.A de gestion:
                      </span>
                      <span v-if="company.CAG == 'inclus'">Inclus</span>
                      <span v-else>{{ parseFloat(company.CAG).toFixed(2) }} €</span>
                    </p>
                    <p>
                      <span class="text-body-2 grey--text  mb-1">Puissance souscrite:
                      </span>
                      <span v-if="company.TURPE == 'inclus'">Inclus</span>
                      <span v-else>{{ parseFloat(Propositions[0].PuissanceSousTurpe).toFixed(2) }} €</span>
                    </p>
                    <p>
                      <span class="text-body-2 grey--text  mb-1">Énergie:
                      </span>
                      <span v-if="company.TURPE == 'inclus'">Inclus</span>
                      <span v-else>{{ parseFloat(Propositions[0].EnergieTurpe).toFixed(2) }} €</span>
                    </p>
                  </v-container>
                </v-col>

                <!-- TAXE -->
                <v-col
                  cols="12"
                  sm="6"
                  md="3"
                  lg="3"
                  xl="3"
                  class="px-4 pt-4 d-flex flex-column"
                >
                  <v-card class="pl-4 pr-3 pb-2 pt-4">
                    <p class="text-body-1 blue--text  mb-3">
                      <span class="text-body-1 grey--text  mb-1">TAXE: </span>
                      {{ company.budgetTaxe.toFixed(2) }} €
                    </p>
                  </v-card>
                  <v-container class="pl-4 pr-3 pb-2 pt-4">
                    <!-- <p>
                      <span class="text-body-2 grey--text  mb-1"
                        >Locales communales:
                      </span>
                      <span
                        >{{ parseFloat(company.taxe_communal).toFixed(2) }} €
                      </span>
                    </p> -->
                    <!-- <p>
                      <span class="text-body-2 grey--text  mb-1"
                        >Locales départementales:
                      </span>
                      <span
                        >{{
                          parseFloat(company.taxe_departemantale).toFixed(2)
                        }}
                        €</span
                      >
                    </p> -->
                    <!-- <p>
                      <span class="text-body-2 grey--text  mb-1"
                        >TCFE:
                      </span>
                      <span
                        >{{
                          (parseFloat(company.TAXE)).toFixed(2)
                        }}
                        €</span
                      >
                    </p> -->
                    <p>
                      <span class="text-body-2 grey--text  mb-1">CSPE: </span>
                      <span>{{ parseFloat(company.CSPE).toFixed(2) }}€/Mwh</span>
                    </p>
                    <p>
                      <span class="text-body-2 grey--text  mb-1">CTA: </span>
                      <span>{{ parseFloat(company.CTA).toFixed(2) }}€/ans</span>
                    </p>
                  </v-container>
                </v-col>
              </v-row>
            
            </v-card>
          </v-expansion-panel-content>
        </v-expansion-panel>
    </v-list-item>
  </v-expansion-panels>
  </div>
</template>

<script>
import TestView from '../../views/TestView'
import premioContrat from '../../views/edition-contrat/primeoEnergie.vue'
import engiePro from '../../views/edition-contrat/engiePro.vue'
import gazelContrat from '../../views/edition-contrat/gazelContrat.vue'
import Totale from '../../views/edition-contrat/tataleContrat.vue'
import edsContrat from '../../views/edition-contrat/EDS.vue'
import TotalHorizon from '../../views/edition-contrat/totalHorizon.vue'
import axios from 'axios'
export default {
  name: 'ListPropostions',
  props: {
    Propositions: [],
    typeCompteur: null,
    panel: [],
    fournisseurActuelle: null,
    selected: [],
    numCompteur: null,
  },
  components: {
    TestView,
    premioContrat,
    engiePro,
    gazelContrat,
    Totale,
    edsContrat,
    TotalHorizon,
  },
  data() {
    return {
      
      dialog: false,
      error: false,
      loading: false,
      messageDialoge: "",
      clickEvent: false,
      show: false,
    }
  },
  methods: {
    validOffre(offerId, transactionId, demandeur, firstName,  lastName,  phone,  email){
      this.dialog = true
      this.loading = true
      var formData = new FormData()
      formData.append('offerId', offerId)
      formData.append('transactionId', transactionId)
      formData.append('demandeur', demandeur)
      formData.append('firstName', firstName)
      formData.append('lastName', lastName)
      formData.append('phone', phone)
      formData.append('email', email)
      formData.append('numCompteur', this.numCompteur)
      
      // cout capa
      //upload pdf file to php server
      axios({
        url: process.env.VUE_APP_URL_API_CLIENT + 'valideOffrePrimeo.php/',
        method: 'POST',
        data: formData,
        
      }).then((res) => {
        this.dialog = false
        this.loading = false
      }).catch((err) => {
        this.dialog = false
        this.loading = false
      })
    },
    getImageLogo(logo) {
      return process.env.VUE_APP_URL_API_CLIENT + 'logoParticulier/' + logo
    },
    calculeTotalCout(company) {
      var array = []
      if (this.typeCompteur === 'c3') {
        array = [
          company.unitairepointe,
          company.unitairehph,
          company.unitairehch,
          company.unitairehpe,
          company.unitairehce,
          company.mensuel,
        ]
      } else if (this.typeCompteur === 'c4') {
        array = [
          company.unitairehph,
          company.unitairehch,
          company.unitairehpe,
          company.unitairehce,
          company.mensuel,
        ]
      } else if (this.typeCompteur === 'c5base') {
        array = [company.unitaire, company.mensuel]
      } else if (this.typeCompteur === 'c5hp') {
        array = [company.unitairehp, company.unitairehc, company.mensuel]
      }
      var sum = 0
      for (var i = 0; i < array.length; i++) {
        if (typeof array[i] === 'number' || !isNaN(array[i])) {
          sum = sum + Math.round(parseFloat(array[i]) * 100) / 100
        }
      }
      return sum
    },
    calculeTotalCapa(company) {
      var array = []
      if (this.typeCompteur === 'c3') {
        array = [
          company.capa_pointe,
          company.capa_hph,
          company.capa_hch,
          company.capa_hpe,
          company.capa_hce,
        ]
      } else if (this.typeCompteur === 'c4') {
        array = [
          company.capa_hph,
          company.capa_hch,
          company.capa_hpe,
          company.capa_hce,
        ]
      } else if (this.typeCompteur === 'c5base') {
        array = [company.capa_base]
      } else if (this.typeCompteur === 'c5hp') {
        array = [company.capa_hp, company.capa_hc]
      }
      var sum = 0
      for (var i = 0; i < array.length; i++) {
        if (typeof array[i] === 'number' || !isNaN(array[i])) {
          sum = sum + Math.round(parseFloat(array[i]) * 100) / 100
        }
      }
      return sum
    },
    calculeTotalTurpe(company) {
      var array = [company.TURPE, company.CACC, company.CAG]
      var sum = 0
      for (var i = 0; i < array.length; i++) {
        if (typeof array[i] === 'number' || !isNaN(array[i])) {
          sum = sum + Math.round(parseFloat(array[i]) * 100) / 100
        }
      }
      return sum
    },
    calculeTotalTaxe(company) {
      var array = [
        company.taxe_communal,
        company.taxe_departemantale,
        company.CSPE,
        company.CTA,
        company.cee,
      ]
      var sum = 0
      for (var i = 0; i < array.length; i++) {
        if (typeof array[i] === 'number' || !isNaN(array[i])) {
          sum = sum + Math.round(parseFloat(array[i]) * 100) / 100
        }
      }
      return sum
    },
  },
}
</script>
