<style>
.checkboxAllClicked {
  background-color: rgba(25, 118, 210, 0.12);
  opacity: 1;
}
.checkboxAllClickedText {
  color: #1976d2 !important;
}
.checkboxAll:hover {
  background-color: rgba(0, 0, 0, 0.04);
}
.td-valeur {
  align-items: center;
  flex-direction: column;
}

.td-statut {
  align-items: center;
  flex-direction: column;
}
.td-table,
.td-valeur,
.td-statut {
  display: flex;
  justify-content: center;
}
.d1 {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: flex-end;
}
.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  padding: 12px 16px;
  z-index: 1;
}
.one-line {
  text-align: center;
  font-size: 15px;
  overflow: hidden;
  white-space: nowrap;
}
.header th {
  height: 2px;
  line-height: 2px;
}
.radioButton {
  display: inline-block;
}

.color-paye {
  color: #1b5e20;
}

.color-validation {
  color: #137ae4;
}

.color-valide {
  color: #13e464;
}

.color-annuler {
  color: #ff5733;
}
.hide {
  display: none;
}
</style>
<template>
  <div>
    <Loading
      v-if="loadingDialogGlobal"
      :dialogProps="loadingDialogGlobal"
      :messageDialogeProps="messageGlobal"
      :loadingProps="loadingGlobalValue"
      :errorProps="errorGlobalValue"
      @closeDialog="loadingDialogGlobal = $event"
    />
    <v-card class="body">
      <v-dialog v-model="dialogSheetFilter" width="500">
        <v-card height="100%">
          <v-toolbar :color="$colors[0]" dark>
            <v-card-title
              class="d-flex justify-content-center text-body-2 text-lg-h6 align-items-center"
            >
              <span>Formulaire Excel</span
              ><v-icon class="ml-2">mdi-file-excel-outline</v-icon>
            </v-card-title>
          </v-toolbar>
          <div style="height: 100%" class="pa-4 d-flex flex-column">
            <v-form ref="formCreationExcel">
              <div class="d-flex flex-column justify-center">
                <v-row
                  v-for="(item, index) in FilterList"
                  v-if="index == 0"
                  :key="index"
                  class="ma-2"
                >
                  <v-autocomplete
                    v-model="vendeurFormulaire"
                    :rules="[$rules.requiredLength]"
                    multiple
                    outlined
                    label="Vendeur"
                    :items="items.nom"
                  >
                    <template v-slot:prepend-item>
                      <div>
                        <v-checkbox
                          @click="selectAll(checkboxModelVendeur, 'vendeur')"
                          v-model="checkboxModelVendeur"
                          :class="
                            checkboxModelVendeur == false
                              ? 'checkboxAll'
                              : 'checkboxAllClicked'
                          "
                          class="px-4"
                        >
                          <template v-slot:label>
                            <span
                              :class="
                                checkboxModelVendeur == false
                                  ? ''
                                  : 'checkboxAllClickedText'
                              "
                              class="ml-6"
                            >
                              Sélectionnez tous</span
                            >
                          </template>
                        </v-checkbox>
                      </div>
                    </template>
                    <template v-slot:selection="{ item, index }">
                      <v-chip v-if="index === 0">
                        <span>{{ item }}</span>
                      </v-chip>
                      <span v-if="index === 1" class="grey--text text-caption">
                        (+{{ vendeurFormulaire.length - 1 }} vendeurs)
                      </span>
                    </template>
                  </v-autocomplete>
                  <v-autocomplete
                    :rules="[$rules.requiredLength]"
                    v-model="fournisseurFormulaire"
                    multiple
                    outlined
                    label="Fournisseur"
                    :items="items.fournisseur"
                  >
                    <template v-slot:selection="{ item, index }">
                      <v-chip v-if="index === 0">
                        <span>{{ item }}</span>
                      </v-chip>
                      <span v-if="index === 1" class="grey--text text-caption">
                        (+{{ fournisseurFormulaire.length - 1 }} fournisseurs)
                      </span>
                    </template>
                    <template v-slot:prepend-item>
                      <div>
                        <v-checkbox
                          @click="selectAll(checkboxModelFournisseur, 'fournisseur')"
                          v-model="checkboxModelFournisseur"
                          :class="
                            checkboxModelFournisseur == false
                              ? 'checkboxAll'
                              : 'checkboxAllClicked'
                          "
                          class="px-4"
                        >
                          <template v-slot:label>
                            <span
                              :class="
                                checkboxModelFournisseur == false
                                  ? ''
                                  : 'checkboxAllClickedText'
                              "
                              class="ml-6"
                            >
                              Sélectionnez tous</span
                            >
                          </template>
                        </v-checkbox>
                      </div>
                    </template>
                  </v-autocomplete>
                  <v-col
                    v-if="
                      vendeurFormulaire.length > 0 && fournisseurFormulaire.length > 0
                    "
                    cols="12"
                    class="pa-0"
                    ><v-select
                      outlined
                      label="Affichage par :"
                      placeholder="fournisseur"
                      v-model="selectSortedExcel"
                      :items="listSortedExcelComputed"
                    ></v-select>
                    <!--<span>Affichage par :</span>
                    <v-radio-group v-model="row" row>
                      <v-radio
                        v-if="checkboxModelFournisseur && checkboxModelVendeur"
                        label="Date"
                        value="date"
                      ></v-radio>
                      <v-radio
                        style="font-weight: 300"
                        label="Vendeur"
                        value="Vendeur"
                      ></v-radio>
                      <v-radio
                        style="font-weight: 300"
                        label="Fournisseur"
                        value="fournisseur"
                      ></v-radio>
                    </v-radio-group>-->
                  </v-col>
                  <v-col cols="12" class="pa-0">
                    <v-menu
                      v-model="menu2"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="dateFormulaire2"
                          :rules="[isRangeDateValide()]"
                          prepend-inner-icon="mdi-calendar"
                          readonly
                          outlined
                          v-bind="attrs"
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="dateFormulaire2"
                        range
                        no-title
                      ></v-date-picker>
                    </v-menu>
                  </v-col>
                </v-row>
                <v-btn
                  class="white--text"
                  :color="$colors[0]"
                  @click="createSheet(selectSortedExcel)"
                  >Création Excel</v-btn
                >
              </div>
            </v-form>
          </div>
        </v-card>
      </v-dialog>
      <div class="tableFilters d-flex flex-row align-items-center ml-lg-6 ml-2">
        <input
          class="form-control search-input mt-4"
          type="text"
          v-model="search"
          placeholder="Rechercher..."
          @input="filterResult({ type: 'search', key: search })"
          style="width: 300px"
        />
        <v-btn color="primary" class="ml-lg-4 mt-4 text-white" @click="resetFilter"
          >réinitialiser les filtres</v-btn
        >
        <v-btn
          class="mt-4 ml-4 white--text"
          :color="$colors[0]"
          @click="openDialogSheetFilter()"
        >
          <span>excel</span>
          <v-icon dense class="ml-2">mdi-file-excel-outline</v-icon>
        </v-btn>
        <!--<v-btn color="primary"  class="ml-lg-4 mt-4 text-white" @click="extraireCsv('file')">format CSV<v-icon>mdi-file-download-outline</v-icon></v-btn>-->
      </div>
      <!--<dataTableAvancer class="ma-8" :columns="columns" :sortKey="sortKey" :sortOrders="sortOrders" @sort="sortBy" 
              @search="rechercheData" @filter="filterResult" :items="items">-->
      <table
        id="table"
        class="ma-lg-5 ma-2 table table-striped table-bordered"
        style="display: block; width: 98%; min-height: 300px; overflow-x: auto"
      >
        <thead style="background-color: #103a5e; color: white">
          <tr class="header">
            <th
              style="text-align: left; max-height: 50px"
              v-for="column in columns"
              :key="column.name"
              v-if="column.sortable"
              :class="
                sortKey == column.name
                  ? sortOrders[column.name] > 0
                    ? 'sorting_asc'
                    : 'sorting_desc'
                  : 'sorting'
              "
            >
              <div v-if="column.name != 'date'">
                <div style="display: flex; align-items: center">
                  <small class="one-line ma-0 pa-5" style="min-width: 120px"
                    >{{ column.label }}
                  </small>
                  <v-icon color="#fff" @click="sortBy(column.name)">mdi-sort</v-icon>
                  <v-icon
                    color="#fff"
                    v-if="column.filter == true"
                    @click="clickToShowFilterModal(column.name)"
                    >mdi-filter-menu-outline</v-icon
                  >
                </div>
                <v-dialog
                  v-model="clickedItemFilter == column.name && column.name != 'dateDF'"
                  max-width="600px"
                >
                  <v-card>
                    <v-toolbar :color="$colors[0]" dark>
                      <v-card-title
                        class="d-flex justify-content-center text-body-2 text-lg-h6 align-items-center"
                        >Filtrer par {{ column.label }}</v-card-title
                      >
                    </v-toolbar>
                    <v-card-title class="pa-0">
                      <v-select
                        v-if="column.filter == true"
                        @input="filterResult({ type: 'filter', items: filterItem })"
                        v-model="filterItem[column.name]"
                        :items="items[column.name]"
                        hide-no-data
                        dense
                        multiple
                      >
                        <template v-slot:selection="{ item, index }">
                          <v-chip v-if="index === 0">
                            <v-tooltip v-if="item.length >= 15" bottom>
                              <template v-slot:activator="{ on, attrs }">
                                <small class="td-table" v-bind="attrs" v-on="on">
                                  {{ item.substring(0, 20) + "..." }}
                                </small>
                              </template>
                              <span>{{ item }}</span>
                            </v-tooltip>
                            <small class="td-table" v-else>
                              {{ item }}
                            </small>
                          </v-chip>
                          <span v-if="index === 1" class="grey--text text-caption">
                            (+{{ items[column.name].length - 1 }}
                            {{
                              items[column.name].length - 1 > 1 ? "éléments" : "élément"
                            }})
                          </span>
                        </template>
                        <template v-slot:prepend-item>
                          <v-list-item ripple class="pa-0">
                            <v-list-item-content>
                              <div class="d-flex justify-space-between">
                                <v-btn
                                  class="mx-2"
                                  style="cursor: pointer"
                                  @click="selectTout(column.name, filterItem)"
                                >
                                  <v-icon>mdi-select-remove</v-icon>
                                  Cliquez pour tout sélectionner
                                </v-btn>
                              </div>
                            </v-list-item-content>
                          </v-list-item>
                          <v-divider class="mt-2"></v-divider>
                        </template>
                      </v-select>
                    </v-card-title>
                  </v-card>
                </v-dialog>
              </div>
              <div v-else>
                <div style="display: flex; align-items: center">
                  <small class="one-line ma-0 pa-5" style="min-width: 120px"
                    >{{ column.label }}
                  </small>
                  <v-icon color="#fff" @click="sortBy(column.name)">mdi-sort</v-icon>
                </div>
              </div>
            </th>
            <th
              style="text-align: left"
              :key="column.name"
              v-else
              :class="
                sortKey == column.name
                  ? sortOrders[column.name] > 0
                    ? 'sorting_asc'
                    : 'sorting_desc'
                  : 'sorting'
              "
              :style="'cursor:pointer;'"
            >
              <div style="display: flex; align-items: center">
                <small class="one-line ma-0 pa-5" style="min-width: 120px">{{
                  column.label
                }}</small>
                <v-icon
                  color="#fff"
                  v-if="column.filter == true"
                  @click="clickToShowFilterModal(column.name)"
                  >mdi-filter-menu-outline</v-icon
                >
              </div>
              <v-dialog
                v-model="clickedItemFilter == column.name && column.name == 'dateDF'"
                max-width="600px"
              >
                <v-card>
                  <v-toolbar :color="$colors[0]" dark>
                    <v-card-title
                      class="d-flex justify-content-center text-body-2 text-lg-h6 align-items-center"
                      >Filter par {{ column.label }}</v-card-title
                    >
                  </v-toolbar>
                  <small class="pa-2"
                    >Sélectionnez deux dates pour définir un intervalle</small
                  >
                  <v-card-title class="pa-2" @click="openDropDown(column.name)">
                    <v-menu
                      ref="menu"
                      v-model="menu"
                      :close-on-content-click="false"
                      :return-value.sync="date"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="date"
                          dense
                          label="Date"
                          prepend-icon="mdi-calendar"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker v-model="date" no-title range scrollable>
                        <v-spacer></v-spacer>
                        <v-btn text color="primary" @click="menu = false"> Cancel </v-btn>
                        <v-btn text color="primary" @click="datePicker()"> OK </v-btn>
                      </v-date-picker>
                    </v-menu>
                  </v-card-title>
                </v-card>
              </v-dialog>
            </th>
          </tr>
        </thead>
        <tbody v-if="projects.length > 0 && loadingTable == false">
          <tr v-for="(project, index) in projects" :key="project.id">
            <td class="td">
              <div class="td-valeur">
                <div :id="'Valuedate' + project.id" style="width: 100%">
                  <div class="d1">
                    <span>
                      {{ project.date }}
                    </span>
                    <v-icon
                      @click="tryUpdateValue('date', project.id)"
                      :id="'buttonTryEditdate' + project.id"
                      class="pull-right ml-6"
                      style="display: inline-block"
                      color="grey darken-4"
                    >
                      mdi-playlist-edit
                    </v-icon>
                  </div>
                </div>
                <div>
                  <input
                    type="date"
                    :id="'inputValuedate' + project.id"
                    :value="dateFormatMinus(project.date)"
                    style="display: none"
                    class="form-control form-control-sm"
                  />

                  <v-icon
                    @click="annulerUpdateValue('date', project.id)"
                    :id="'buttonAnnulerEditdate' + project.id"
                    class="left-icon-pos pull-left"
                    style="display: none"
                    color="red darken-4"
                  >
                    mdi-marker-cancel
                  </v-icon>
                  <v-icon
                    @click="modifierValue('date', project.id)"
                    :id="'buttonValideEditdate' + project.id"
                    class="left-icon-pos pull-left ml-8"
                    style="display: none"
                    color="green darken-4"
                  >
                    mdi-cloud-check
                  </v-icon>
                </div>
              </div>
              <!--<div class="td-table">
                  {{ project.date }}
                </div>-->
            </td>
            <td class="td">
              <div class="td-table">
                {{ project.Commercial }}
              </div>
            </td>
            <td class="td">
              <v-tooltip v-if="project.Raison.length >= 15" bottom>
                <template v-slot:activator="{ on, attrs }">
                  <small class="td-table" v-bind="attrs" v-on="on">
                    <router-link
                      :to="{
                        path: '/les-profils-societes',
                        query: { id: project.idSociete },
                      }"
                      >{{ project.Raison.substring(0, 20) + "..." }}</router-link
                    >
                  </small>
                  <small>{{ project.siret }}</small>
                </template>
                <span>{{ project.Raison }}</span>
              </v-tooltip>
              <small class="td-table" v-else>
                <router-link
                  :to="{
                    path: '/les-profils-societes',
                    query: { id: project.idSociete },
                  }"
                  >{{ project.Raison }}</router-link
                >
              </small>
              <small>{{ project.Site }}</small>
            </td>
            <td class="td">
              <small class="td-table">
                {{ project.fournisseur }}
              </small>
            </td>
            <td class="td">
              <div class="td-table">
                {{ project.type }}
              </div>
            </td>
            <td class="td">
              <div class="td-table">
                {{ project.numCompteur }}
              </div>
            </td>
            <td class="td">
              <div class="td-valeur">
                <div :id="'consommation' + project.id" style="width: 100%">
                  <div class="d1">
                    <span>
                      {{
                        isNaN(project.consommation)
                          ? project.consommation
                          : Number(project.consommation).toLocaleString("fr-FR")
                      }}
                      Mwh
                    </span>
                    <v-icon
                      @click="tryUpdateValue('consommation', project.id)"
                      :id="'buttonTryEditConsommation' + project.id"
                      class="pull-right ml-6"
                      style="display: inline-block"
                      color="grey darken-4"
                    >
                      mdi-playlist-edit
                    </v-icon>
                  </div>
                </div>
                <div class="d-flex flex-column">
                  <v-radio-group
                    style="display: none; margin: 10px"
                    :class="'radiovalueConsommation' + project.id"
                    v-model="RelierAuCalcule"
                    row
                    label="Influence sur le valeur Brut"
                  >
                    <v-radio label="Oui" value="oui"></v-radio>
                    <v-radio label="Non" value="non"></v-radio>
                  </v-radio-group>
                  <b-form-input
                    type="text"
                    :id="'inputConsommation' + project.id"
                    :value="project.consommation"
                    style="display: none; width: 80%"
                    placeholder="CAR"
                  ></b-form-input>
                  <div class="d-flex justify-content-center">
                    <v-icon
                      @click="annulerUpdateValue('consommation', project.id)"
                      :id="'buttonAnnulerEditConsommation' + project.id"
                      class="left-icon-pos pull-left"
                      style="display: none"
                      color="red darken-4"
                    >
                      mdi-marker-cancel
                    </v-icon>
                    <v-icon
                      @click="modifierValue('consommation', project.id)"
                      :id="'buttonValideEditConsommation' + project.id"
                      class="left-icon-pos pull-left ml-8"
                      style="display: none"
                      color="green darken-4"
                    >
                      mdi-cloud-check
                    </v-icon>
                  </div>
                </div>
              </div>
            </td>
            <!--<td class="td">
              <div class="td-table">
                                  {{project.duree}} mois
                              </div>
              <div class="td-table">
                <div :id="'valueduree' + project.id" style="width: 100%">
                  <div class="d1">
                    <span> {{ project.duree }} Mois </span>
                    <v-icon
                      @click="tryUpdateValue('duree', project.id)"
                      :id="'buttonTryEditduree' + project.id"
                      class="pull-right ml-6"
                      style="display: inline-block"
                      color="grey darken-4"
                    >
                      mdi-playlist-edit
                    </v-icon>
                  </div>
                </div>
                <div class="d-flex flex-column">
                  <v-radio-group
                    style="display: none; margin: 10px"
                    :class="'radioduree' + project.id"
                    v-model="RelierAuCalcule"
                    row
                    label="Influence sur le valeur Brut:"
                  >
                    <v-radio label="Oui" value="oui"></v-radio>
                    <v-radio label="Non" value="non"></v-radio>
                  </v-radio-group>
                  <b-form-input
                    type="text"
                    :id="'inputduree' + project.id"
                    :value="project.duree"
                    style="display: none; width: 80%"
                    placeholder="Duree"
                  ></b-form-input>
                  <div class="d-flex justify-content-center">
                    <v-icon
                      @click="annulerUpdateValue('duree', project.id)"
                      :id="'buttonAnnulerEditduree' + project.id"
                      class="left-icon-pos pull-left"
                      style="display: none"
                      color="red darken-4"
                    >
                      mdi-marker-cancel
                    </v-icon>
                    <v-icon
                      @click="modifierValue('duree', project.id)"
                      :id="'buttonValideEditduree' + project.id"
                      class="left-icon-pos pull-left ml-8"
                      style="display: none"
                      color="green darken-4"
                    >
                      mdi-cloud-check
                    </v-icon>
                  </div>
                </div>
              </div>
            </td>-->
            <td class="td">
              <div class="td-table">
                {{ project.dateDF }}
              </div>
            </td>
            <td class="td">
              <div class="td-table">
                {{ project.dateFin }}
              </div>
            </td>

            <td class="td">
              <div class="td-table">
                <div :id="'valuemarge' + project.id" style="width: 100%">
                  <div class="d1">
                    <span>
                      {{
                        isNaN(project.marge)
                          ? project.marge
                          : Number(project.marge).toLocaleString("fr-FR")
                      }}
                      €/Mwh
                    </span>
                    <v-icon
                      @click="tryUpdateValue('valuemarge', project.id)"
                      :id="'buttonTryEditvaluemarge' + project.id"
                      class="pull-right ml-6"
                      style="display: inline-block"
                      color="grey darken-4"
                    >
                      mdi-playlist-edit
                    </v-icon>
                  </div>
                </div>
                <div class="d-flex flex-column">
                  <v-radio-group
                    style="display: none; margin: 10px"
                    :class="'radiovalueMarge' + project.id"
                    v-model="RelierAuCalcule"
                    row
                    label="Influence sur les valeurs:"
                  >
                    <v-radio label="Oui" value="oui"></v-radio>
                    <v-radio label="Non" value="non"></v-radio>
                  </v-radio-group>
                  <b-form-input
                    type="text"
                    :id="'inputvaluemarge' + project.id"
                    :value="project.marge"
                    style="display: none; width: 80%"
                    placeholder="Marge"
                  ></b-form-input>
                  <div class="d-flex justify-content-center">
                    <v-icon
                      @click="annulerUpdateValue('valuemarge', project.id)"
                      :id="'buttonAnnulerEditvaluemarge' + project.id"
                      class="left-icon-pos pull-left"
                      style="display: none"
                      color="red darken-4"
                    >
                      mdi-marker-cancel
                    </v-icon>
                    <v-icon
                      @click="modifierValue('valuemarge', project.id)"
                      :id="'buttonValideEditvaluemarge' + project.id"
                      class="left-icon-pos pull-left ml-8"
                      style="display: none"
                      color="green darken-4"
                    >
                      mdi-cloud-check
                    </v-icon>
                  </div>
                </div>
              </div>
            </td>
            <td class="td">
              <div class="td-valeur">
                <div :id="'valuevaleurBrut' + project.id" style="width: 100%">
                  <div class="d1">
                    <span>
                      {{
                        isNaN(project.valeurBrut)
                          ? project.valeurBrut
                          : Number(project.valeurBrut).toLocaleString("fr-FR")
                      }}
                      €
                    </span>
                    <v-icon
                      @click="tryUpdateValue('valeurBrut', project.id)"
                      :id="'buttonTryEditvaleurBrut' + project.id"
                      class="pull-right ml-6"
                      style="display: inline-block"
                      color="grey darken-4"
                    >
                      mdi-playlist-edit
                    </v-icon>
                  </div>
                </div>
                <div class="d-flex flex-column">
                  <v-radio-group
                    style="display: none; margin: 10px"
                    :class="'radiovaluevaleurBrut' + project.id"
                    v-model="RelierAuCalcule"
                    row
                    label="Influence sur le valeur AA:"
                  >
                    <v-radio label="Oui" value="oui"></v-radio>
                    <v-radio label="Non" value="non"></v-radio>
                  </v-radio-group>
                  <b-form-input
                    type="text"
                    :id="'inputvaluevaleurBrut' + project.id"
                    :value="project.valeurBrut"
                    style="display: none; width: 80%"
                    placeholder="Valeur Brut"
                  ></b-form-input>
                  <div class="d-flex justify-content-center">
                    <v-icon
                      @click="annulerUpdateValue('valeurBrut', project.id)"
                      :id="'buttonAnnulerEditvaleurBrut' + project.id"
                      class="left-icon-pos pull-left"
                      style="display: none"
                      color="red darken-4"
                    >
                      mdi-marker-cancel
                    </v-icon>
                    <v-icon
                      @click="modifierValue('valeurBrut', project.id)"
                      :id="'buttonValideEditvaleurBrut' + project.id"
                      class="left-icon-pos pull-left ml-8"
                      style="display: none"
                      color="green darken-4"
                    >
                      mdi-cloud-check
                    </v-icon>
                  </div>
                </div>
              </div>
            </td>
            <td class="td">
              <div class="td-valeur">
                <div :id="'valuepourcentage' + project.id" style="width: 100%">
                  <div class="d1">
                    <span> {{ project.pourcentage }} % </span>
                    <v-icon
                      @click="tryUpdateValue('pourcentage', project.id)"
                      :id="'buttonTryEditpourcentage' + project.id"
                      class="pull-right ml-6"
                      style="display: inline-block"
                      color="grey darken-4"
                    >
                      mdi-playlist-edit
                    </v-icon>
                  </div>
                </div>
                <div class="d-flex flex-column">
                  <fieldset
                    :class="'radiovaluepourcentage' + project.id"
                    style="display: none"
                  >
                    <v-radio-group
                      style="display: none; margin: 10px"
                      :class="'radiovaluepourcentage' + project.id"
                      v-model="RelierAuCalcule"
                      row
                      label="Influence sur le valeur AA:"
                    >
                      <v-radio label="Oui" value="oui"></v-radio>
                      <v-radio label="Non" value="non"></v-radio>
                    </v-radio-group>
                    <input
                      type="number"
                      :id="'inputvaluepourcentage' + project.id"
                      :value="project.pourcentage"
                      style="display: none"
                      class="form-control form-control-sm"
                      max="100"
                      min="0"
                    />
                  </fieldset>
                  <div class="d-flex justify-content-center">
                    <v-icon
                      @click="annulerUpdateValue('pourcentage', project.id)"
                      :id="'buttonAnnulerEditpourcentage' + project.id"
                      class="left-icon-pos pull-left"
                      style="display: none"
                      color="red darken-4"
                    >
                      mdi-marker-cancel
                    </v-icon>
                    <v-icon
                      @click="modifierValue('pourcentage', project.id)"
                      :id="'buttonValideEditpourcentage' + project.id"
                      class="left-icon-pos pull-left ml-8"
                      style="display: none"
                      color="green darken-4"
                    >
                      mdi-cloud-check
                    </v-icon>
                  </div>
                </div>
              </div>
            </td>
            <td class="td">
              <div class="td-valeur">
                <div :id="'Value' + project.id" style="width: 100%">
                  <div class="d1">
                    <span>
                      {{
                        isNaN(project.valeur)
                          ? project.valeur
                          : Number(project.valeur).toLocaleString("fr-FR")
                      }}
                      €
                    </span>
                    <v-icon
                      @click="tryUpdateValue('value', project.id)"
                      :id="'buttonTryEdit' + project.id"
                      class="pull-right ml-6"
                      style="display: inline-block"
                      color="grey darken-4"
                    >
                      mdi-playlist-edit
                    </v-icon>
                  </div>
                </div>
                <div>
                  <input
                    type="text "
                    :id="'inputValue' + project.id"
                    :value="project.valeur"
                    style="display: none"
                    class="form-control form-control-sm"
                  />

                  <v-icon
                    @click="annulerUpdateValue('value', project.id)"
                    :id="'buttonAnnulerEdit' + project.id"
                    class="left-icon-pos pull-left"
                    style="display: none"
                    color="red darken-4"
                  >
                    mdi-marker-cancel
                  </v-icon>
                  <v-icon
                    @click="modifierValue('value', project.id)"
                    :id="'buttonValideEdit' + project.id"
                    class="left-icon-pos pull-left ml-8"
                    style="display: none"
                    color="green darken-4"
                  >
                    mdi-cloud-check
                  </v-icon>
                </div>
              </div>
            </td>
            <td class="td">
              <div class="td-valeur">
                <div :id="'valueGEMC' + project.id" style="width: 100%">
                  <div class="d1">
                    <span>
                      {{
                        isNaN(project.valeur)
                          ? project.valeurGemc
                          : Number(project.valeurGemc).toLocaleString("fr-FR")
                      }}
                      €
                    </span>
                    <v-icon
                      @click="tryUpdateValue('valueGEMC', project.id)"
                      :id="'buttonTryEditvalueGEMC' + project.id"
                      class="pull-right ml-6"
                      style="display: inline-block"
                      color="grey darken-4"
                    >
                      mdi-playlist-edit
                    </v-icon>
                  </div>
                </div>
                <div>
                  <input
                    type="text "
                    :id="'inputvalueGEMC' + project.id"
                    :value="project.valeurGemc"
                    style="display: none"
                    class="form-control form-control-sm"
                  />

                  <v-icon
                    @click="annulerUpdateValue('valueGEMC', project.id)"
                    :id="'buttonAnnulerEditvalueGEMC' + project.id"
                    class="left-icon-pos pull-left"
                    style="display: none"
                    color="red darken-4"
                  >
                    mdi-marker-cancel
                  </v-icon>
                  <v-icon
                    @click="modifierValue('valueGEMC', project.id)"
                    :id="'buttonValideEditvalueGEMC' + project.id"
                    class="left-icon-pos pull-left ml-8"
                    style="display: none"
                    color="green darken-4"
                  >
                    mdi-cloud-check
                  </v-icon>
                </div>
              </div>
            </td>
            <td class="d-flex flex-column align-items-start">
              <div class="d-flex flex-row justify-content-center align-items-center">
                <p class="color-paye mb-0" v-if="project.status == 'paye'">
                  <v-icon color="#1B5E20" class="mr-2"> mdi-cash-check </v-icon
                  ><b>encaissé</b>
                </p>
                <p class="color-valide mb-0" v-if="project.status == 'valide'">
                  <v-icon color="green accent-3" class="mr-2"> mdi-check </v-icon
                  ><b>validé</b>
                </p>
                <p class="color-annuler mb-0" v-if="project.status == 'annuler'">
                  <v-icon color="red" class="mr-2"> mdi-cancel </v-icon><b>annulé</b>
                </p>
                <p class="mb-0" v-if="project.status == 'en Attend'">
                  <v-icon color="gray" class="mr-2"> mdi-timer-refresh-outline </v-icon
                  ><b>en attente</b>
                </p>
                <p class="color-valide mb-0" v-if="project.status == 'Accepter'">
                  <v-icon color="green accent-3" class="mr-2"> mdi-check </v-icon
                  ><b>validé</b>
                </p>
                <p class="mb-0" v-if="project.status == 'en validation'">
                  <v-icon color="accent-3" class="mr-2"> mdi-send-check </v-icon
                  ><b>en validation</b>
                </p>

                <v-icon
                  size="22"
                  class="dropdown-toggle"
                  data-toggle="collapse"
                  :data-target="'#collapseStatut' + index"
                  aria-expanded="false"
                  :aria-controls="'collapseStatut' + index"
                  left
                  style="cursor: pointer"
                >
                  mdi-chevron-down
                </v-icon>
              </div>
              <div
                class="text-center collapse"
                :id="'collapseStatut' + index"
                style="border: 0px solid white"
              >
                <v-btn
                  @click="changeStatut(project, 'paye')"
                  v-if="project.status != 'paye'"
                  class="mr-2"
                  icon
                  dark
                  small
                >
                  <v-icon color="#1B5E20"> mdi-cash-check </v-icon>
                </v-btn>
                <v-btn
                  @click="changeStatut(project, 'valide')"
                  v-if="project.status != 'valide ' || project.status != 'Accepter'"
                  class="mr-2"
                  icon
                  small
                  dark
                >
                  <v-icon color="green accent-3"> mdi-check </v-icon>
                </v-btn>
                <v-btn
                  @click="changeStatut(project, 'en validation')"
                  v-if="project.status != 'en validation'"
                  class="mr-2"
                  icon
                  small
                  dark
                >
                  <v-icon color="#137AE4"> mdi-send-check </v-icon>
                </v-btn>
                <v-btn
                  @click="changeStatut(project, 'annuler')"
                  v-if="project.status != 'annuler'"
                  class="mr-2"
                  icon
                  small
                  dark
                >
                  <v-icon color="red"> mdi-cancel </v-icon>
                </v-btn>
              </div>
            </td>
          </tr>
        </tbody>
        <noDataTable
          class="ma-4"
          v-else-if="projects?.length == 0 && loadingTable == false"
        />
        <progressCircular class="ma-8" v-else-if="loadingTable == true" />
      </table>
      <div class="d-flex">
        <v-col class="pa-0 pb-md-2">
          <pagination
            v-if="projects.length > 0"
            :pagination="pagination"
            @selectNumberOfRecords="filterResult"
            @first="filterResult({ type: 'next', numberOfPage: pagination.firstPage })"
            @last="filterResult({ type: 'next', numberOfPage: pagination.lastPage })"
            @prev="filterResult({ type: 'next', numberOfPage: pagination.prevPageUrl })"
            @current="
              filterResult({ type: 'next', numberOfPage: pagination.currentPage })
            "
            @next="filterResult({ type: 'next', numberOfPage: pagination.nextPageUrl })"
          >
          </pagination>
        </v-col>
      </div>
    </v-card>
  </div>
</template>
<script>
import "jquery/dist/jquery.min.js";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/js/bootstrap.bundle";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";

import $ from "jquery";
import jQuery from "jquery";
import moment from "moment";
import dataTableAvancer from "../../components/dataTable/dataTableAvancer.vue";
import noDataTable from "../../components/no-data-table.vue";
import progressCircular from "../../components/progress-circular.vue";
import Pagination from "../../components/dataTable/Pagination.vue";
import Loading from "@/components/ui/loading-component.vue";
import axios from "axios";
export default {
  name: "RapportDeVente",
  components: {
    dataTableAvancer: dataTableAvancer,
    pagination: Pagination,
    noDataTable,
    progressCircular,
    Loading,
  },
  data() {
    let sortOrders = {};
    let columns = [
      { label: "Date", name: "date", sortable: true, filter: true },
      { label: "Vendeur", name: "nom", sortable: true, filter: true },
      { label: "Raison Sociale", name: "raison", sortable: true, filter: true },
      { label: "Fournisseur", name: "fournisseur", sortable: true, filter: true },
      { label: "Energie", name: "type", sortable: true, filter: false },
      { label: "PDL", name: "numCompteur", sortable: true, filter: false },
      { label: "Consommation", name: "Consommation", sortable: true, filter: false },
      // { label: "Durée du contrat", name: "Duree", sortable: true, filter: false },
      { label: "DDF", name: "dateDF", sortable: false, filter: true },
      { label: "Date Fin", name: "dateFin", sortable: false, filter: true },
      { label: "marge", name: "marge", sortable: true, filter: false },
      { label: "Valeur Brut", name: "valeurBrut", sortable: true, filter: false },
      { label: "Pourcentage", name: "Pourcentage", sortable: true, filter: false },
      { label: "Valeur AA", name: "valeurAA", sortable: true, filter: false },
      { label: "Valeur SME", name: "valeurGEMC", sortable: true, filter: false },
      { label: "Statut", name: "status", sortable: true, filter: true },
    ];
    columns.forEach((column) => {
      sortOrders[column.name] = -1;
    });
    return {
      row: false,
      menu2: false,
      vendeurFormulaire: [],
      fournisseurFormulaire: [],
      checkboxModelVendeur: false,
      checkboxModelFournisseur: false,
      dateFormulaire: [],
      dateFormulaire2: [],
      loadingDialogGlobal: false,
      loadingGlobalValue: false,
      messageGlobal: "",
      errorGlobalValue: false,
      FilterList: [
        { label: "Vendeur", value: "Vendeur" },
        { label: "Fournisseur", value: "fournisseur" },
        { label: "Date", value: "date" },
      ],
      selectSortedExcel: "",
      dialogSheetFilter: false,
      clickedItemFilter: "",
      loadingTable: false,
      date: [],
      menu: false,
      //data for header
      isOpenMyDropdown: false,
      closeEnClick: false,
      dialog: false,
      value: "",
      RelierAuCalcule: "non",
      search: "",
      filterItem: {
        date: [],
        nom: [],
        raison: [],
        fournisseur: [],
        type: [],
        numCompteur: [],
        Consommation: [],
        Duree: [],
        marge: [],
        valeurBrut: [],
        Pourcentage: [],
        valeurAA: [],
        valeurGEMC: [],
        status: [],
        dateDebutFMIN: null,
        dateDebutFMAX: null,
      },
      required(fieldName) {
        return (value) =>
          (!!value && value >= 0) ||
          (!!value && value != "") ||
          `Veuillez saisir ${fieldName}`;
      },
      //restdata
      dialog: false,
      loading: false,
      error: false,
      commentRefuser: "",
      messageDialoge: "",
      ouvrireDialogeRefuser: false,
      ouvrireDialogeSupprimer: false,
      projects: [],
      columns: columns,
      sortKey: "date",
      sortOrders: sortOrders,
      perPage: ["15", "15", "30"],
      items: {
        date: [],
        nom: [],
        raison: [],
        fournisseur: [],
        type: [],
        numCompteur: [],
        Consommation: [],
        Duree: [],
        marge: [],
        valeurBrut: [],
        Pourcentage: [],
        valeurAA: [],
        valeurGEMC: [],
        status: [],
        dateDebutFMIN: null,
        dateDebutFMAX: null,
      },
      tableData: {
        draw: 0,
        length: 15,
        search: "",
        column: 0,
        dir: "desc",
        filterItems: null,
        filterType: "all",
      },
      pagination: {
        perPage: ["10", "15", "30", "all"],
        firstPage: 0,
        lastPage: "",
        currentPage: "",
        total: "",
        lastPageUrl: "",
        nextPageUrl: "",
        prevPageUrl: "",
        from: "",
        to: "",
      },

      rules: {
        required(finPhrase) {
          return (value) => !!value || `Veuillez saisir ${finPhrase}`;
        },
      },
    };
  },
  async created() {
    this.loadingTable = true;
    var whoIam = "";
    await this.whoIAm(this.getToken("token")).then((data) => {
      whoIam = data;
    });
    if (whoIam == "noToken") {
      localStorage.removeItem("vendeurName");
      localStorage.removeItem("typeUser");
      localStorage.removeItem("token");
      this.$router.push("/login");
    } else {
      localStorage.setItem("typeUser", whoIam);
    }
    await this.getData();
    await this.getAllDate();
    this.selectSortedExcel = this.FilterList[0].value;
    this.loadingTable = false;
  },
  computed: {
    listSortedExcelComputed: function () {
      if (this.checkboxModelVendeur && this.checkboxModelFournisseur) {
        return ["Vendeur", "fournisseur", "date"];
      } else {
        return ["Vendeur", "fournisseur"];
      }
    },
  },
  methods: {
    isRangeDateValide() {
      if (this.dateFormulaire2.length > 1) {
        return this.dateFormulaire2[0] <=
          this.dateFormulaire2[this.dateFormulaire2.length - 1]
          ? true
          : "La date de début doit etre inférieur a la date de fin";
      } else if (this.dateFormulaire2.length == 1) {
        return true;
      } else {
        return "Champs obligatoire";
      }
    },
    async getAllDate() {
      await axios({
        url: process.env.VUE_APP_URL_API_CLIENT + "getAllDate/",
        method: "POST",
      }).then((res) => {
        this.dateFormulaire = res.data.date;
      });
      this.dateFormulaire2 = [
        this.dateFormulaire[0],
        this.dateFormulaire[this.dateFormulaire.length - 1],
      ];
    },
    selectAll(checkboxValue, type) {
      if (type == "vendeur") {
        if (checkboxValue) {
          this.vendeurFormulaire = [];
          this.items.nom.forEach((element) => {
            if (!this.vendeurFormulaire.includes(element)) {
              this.vendeurFormulaire.push(element);
            }
          });
        } else {
          this.vendeurFormulaire = [];
        }
      } else if (type == "fournisseur") {
        if (checkboxValue) {
          this.fournisseurFormulaire = [];
          this.items.fournisseur.forEach((element) => {
            if (!this.fournisseurFormulaire.includes(element)) {
              this.fournisseurFormulaire.push(element);
            }
          });
        } else {
          this.fournisseurFormulaire = [];
        }
      }
    },
    dateFormatSlach(date) {
      return date.split("-").reverse().join("/");
    },
    dateFormatMinus(date) {
      return date.split("/").reverse().join("-");
    },
    validateDate(dateString) {
      const datePattern = /^(\d{2})\/(\d{2})\/(\d{4})$/;
      if (datePattern.test(dateString)) {
        // Date string matches the "jj/mm/yyyy" format
        const [, day, month, year] = dateString.match(datePattern);
        const dayInt = parseInt(day, 10);
        const monthInt = parseInt(month, 10);
        const yearInt = parseInt(year, 10);

        // Additional validation (e.g., check for valid day, month, year values) can be added here

        // For simplicity, we'll consider any match as valid for now
        return true;
      } else {
        // Date string does not match the format
        return false;
      }
    },
    async createSheet(item) {
      if (this.$refs.formCreationExcel.validate()) {
        if (this.dateFormulaire2.length == 1) {
          this.dateFormulaire2.push(this.dateFormulaire2[0]);
        }
        this.dialogSheetFilter = false;
        this.loadingDialogGlobal = true;
        this.loadingGlobalValue = true;
        switch (item) {
          case "date":
            await axios({
              url: process.env.VUE_APP_URL_API_CLIENT + "createSheetDate/",
              method: "POST",
              data: {
                key: item,
                date: this.dateFormulaire2,
              },
            })
              .then((res) => {
                var url = res.data.result;
                const link = document.createElement("a");
                link.href = url;
                link.download = "test.xlsx";
                link.click();
                console.log(res);

                this.loadingDialogGlobal = false;
                this.loadingGlobalValue = false;
              })
              .catch((err) => {
                console.log(err);
                this.loadingDialogGlobal = true;
                this.loadingGlobalValue = false;
                this.errorGlobalValue = true;
                this.messageGlobal = err;
              });
            break;
          case "fournisseur":
            await axios({
              url: process.env.VUE_APP_URL_API_CLIENT + "createSheetField/",
              method: "POST",
              data: {
                key: item,
                date: this.dateFormulaire2,
                fournisseurs: this.fournisseurFormulaire,
                vendeurs: this.vendeurFormulaire,
              },
            })
              .then((res) => {
                var url = res.data.result;
                const link = document.createElement("a");
                link.href = url;
                link.download = "test.xlsx";
                link.click();
                this.loadingDialogGlobal = false;
                this.loadingGlobalValue = false;
              })
              .catch((err) => {
                console.log(err);
                this.loadingDialogGlobal = true;
                this.loadingGlobalValue = false;
                this.errorGlobalValue = true;
                this.messageGlobal = err;
              });
            break;
          case "Vendeur":
            await axios({
              url: process.env.VUE_APP_URL_API_CLIENT + "createSheetField/",
              method: "POST",
              data: {
                key: item,
                date: this.dateFormulaire2,
                fournisseurs: this.fournisseurFormulaire,
                vendeurs: this.vendeurFormulaire,
              },
            })
              .then((res) => {
                var url = res.data.result;
                const link = document.createElement("a");
                link.href = url;
                link.download = "test.xlsx";
                link.click();
                this.loadingDialogGlobal = false;
                this.loadingGlobalValue = false;
              })
              .catch((err) => {
                console.log(err);
                this.loadingDialogGlobal = true;
                this.loadingGlobalValue = false;
                this.errorGlobalValue = true;
                this.messageGlobal = err;
              });
            break;

          default:
            break;
        }
      }
    },
    openDialogSheetFilter() {
      this.dialogSheetFilter = true;
    },
    clickToShowFilterModal(clickedItemFilter) {
      if (this.clickedItemFilter == clickedItemFilter) {
        this.clickedItemFilter = "";
      } else {
        this.clickedItemFilter = clickedItemFilter;
      }
    },
    downloadCSV(csv, filename) {
      var csvFile;
      var downloadLink;
      csvFile = new Blob([csv], { type: "text/csv" });
      downloadLink = document.createElement("a");
      downloadLink.download = filename;
      downloadLink.href = window.URL.createObjectURL(csvFile);
      downloadLink.style.display = "none";
      document.body.appendChild(downloadLink);
      downloadLink.click();
    },
    extraireCsv(filename) {
      var csv = [];
      var rows = document.querySelectorAll("#table thead tr, #table tbody tr");
      for (var i = 0; i < rows.length; i++) {
        var row = [],
          cols = rows[i].querySelectorAll("td, th p");
        for (var j = 0; j < cols.length; j++) row.push(cols[j].innerText);

        csv.push(row.join(","));
      }
      this.downloadCSV(csv.join("\n"), filename);
    },
    datePicker() {
      this.filterItem.dateDebutFMIN = this.date[0];
      this.filterItem.dateDebutFMAX = this.date[1];
      this.menu = false;

      this.$refs.menu.save(this.date);
      this.filterResult({ type: "filter", items: this.filterItem });
    },
    resetFilter() {
      this.search = "";

      this.date = [];
      this.filterItem.dateDebutFMIN = null;
      this.filterItem.dateDebutFMAX = null;

      this.filterItem = { ...this.items };
      this.filterResult({ type: "filter", items: this.filterItem, numberOfPage: 0 });
    },
    openDropDown(name) {
      for (var i = 0; i < this.columns.length; i++) {
        if (this.columns[i].name != name) {
          $("#dropDown" + this.columns[i].name).css("display", "none");
        }
      }
      var display = $("#dropDown" + name).css("display");
      if (display == "none") {
        $("#dropDown" + name).css("display", "block");
      } else {
        $("#dropDown" + name).css("display", "none");
      }
    },
    selectTout(value, filterItem) {
      if (this.filterItem[value].length == this.items[value].length) {
        this.filterItem[value] = [];
        this.closeEnClick = false;
      } else {
        this.closeEnClick = false;
        this.filterItem[value] = [];
        for (var i = 0; i < this.items[value].length; i++) {
          this.filterItem[value].push(this.items[value][i]);
        }
        this.filterResult({ type: "filter", items: filterItem });
      }
    },
    tryUpdateValue(who, id) {
      if (who === "value") {
        $("#buttonTryEdit" + id).css("display", "none");
        $("#buttonAnnulerEdit" + id).css("display", "inline-block");
        $("#buttonValideEdit" + id).css("display", "inline-block");

        $("#Value" + id).css("display", "none");
        $("#inputValue" + id).css("display", "inline-block");
      }
      if (who === "consommation") {
        $("#buttonTryEditConsommation" + id).css("display", "none");
        $("#buttonAnnulerEditConsommation" + id).css("display", "inline-block");
        $("#buttonValideEditConsommation" + id).css("display", "inline-block");

        $("#consommation" + id).css("display", "none");
        $("#inputConsommation" + id).css("display", "inline-block");
        $(".radiovalueConsommation" + id).css("display", "inline-block");
      }
      if (who === "valueGEMC") {
        $("#buttonTryEditvalueGEMC" + id).css("display", "none");
        $("#buttonAnnulerEditvalueGEMC" + id).css("display", "inline-block");
        $("#buttonValideEditvalueGEMC" + id).css("display", "inline-block");
        $("#valueGEMC" + id).css("display", "none");
        $("#inputvalueGEMC" + id).css("display", "inline-block");
      }
      if (who === "valuemarge") {
        $("#buttonTryEditvaluemarge" + id).css("display", "none");
        $("#buttonAnnulerEditvaluemarge" + id).css("display", "inline-block");
        $("#buttonValideEditvaluemarge" + id).css("display", "inline-block");

        $("#valuemarge" + id).css("display", "none");
        $("#inputvaluemarge" + id).css("display", "inline-block");
        $(".radiovalueMarge" + id).css("display", "inline-block");
      }
      if (who === "valeurBrut") {
        $("#buttonTryEditvaleurBrut" + id).css("display", "none");
        $("#buttonAnnulerEditvaleurBrut" + id).css("display", "inline-block");
        $("#buttonValideEditvaleurBrut" + id).css("display", "inline-block");

        $("#valuevaleurBrut" + id).css("display", "none");
        $("#inputvaluevaleurBrut" + id).css("display", "inline-block");
        $(".radiovaluevaleurBrut" + id).css("display", "inline-block");
      }
      if (who === "duree") {
        $("#buttonTryEditduree" + id).css("display", "none");
        $("#buttonAnnulerEditduree" + id).css("display", "inline-block");
        $("#buttonValideEditduree" + id).css("display", "inline-block");

        $("#valueduree" + id).css("display", "none");
        $("#inputduree" + id).css("display", "inline-block");
        $(".radioduree" + id).css("display", "inline-block");
      }
      if (who === "pourcentage") {
        $("#buttonTryEditpourcentage" + id).css("display", "none");
        $("#buttonAnnulerEditpourcentage" + id).css("display", "inline-block");
        $("#buttonValideEditpourcentage" + id).css("display", "inline-block");

        $("#valuepourcentage" + id).css("display", "none");
        $("#inputvaluepourcentage" + id).css("display", "inline-block");
        $(".radiovaluepourcentage" + id).css("display", "inline-block");
      }
      if (who === "date") {
        $("#buttonTryEditdate" + id).css("display", "none");
        $("#buttonAnnulerEditdate" + id).css("display", "inline-block");
        $("#buttonValideEditdate" + id).css("display", "inline-block");
        $("#valuedate" + id).css("display", "none");
        $("#inputValuedate" + id).css("display", "inline-block");
      }
      this.RelierAuCalcule = "non";
    },
    annulerUpdateValue(who, id) {
      if (who === "value") {
        $("#buttonTryEdit" + id).css("display", "inline-block");
        $("#buttonAnnulerEdit" + id).css("display", "none");
        $("#buttonValideEdit" + id).css("display", "none");
        $("#Value" + id).css("display", "inline-block");
        $("#inputValue" + id).css("display", "none");
      }
      if (who === "consommation") {
        $("#buttonTryEditConsommation" + id).css("display", "inline-block");
        $("#buttonAnnulerEditConsommation" + id).css("display", "none");
        $("#buttonValideEditConsommation" + id).css("display", "none");

        $("#consommation" + id).css("display", "inline-block");
        $("#inputConsommation" + id).css("display", "none");
        $(".radiovalueConsommation" + id).css("display", "none");
        this.RelierAuCalcule = "non";
      }
      if (who === "valueGEMC") {
        $("#buttonTryEditvalueGEMC" + id).css("display", "inline-block");
        $("#buttonAnnulerEditvalueGEMC" + id).css("display", "none");
        $("#buttonValideEditvalueGEMC" + id).css("display", "none");
        $("#valueGEMC" + id).css("display", "inline-block");
        $("#inputvalueGEMC" + id).css("display", "none");
      }
      if (who === "valuemarge") {
        $("#buttonTryEditvaluemarge" + id).css("display", "inline-block");
        $("#buttonAnnulerEditvaluemarge" + id).css("display", "none");
        $("#buttonValideEditvaluemarge" + id).css("display", "none");
        $("#valuemarge" + id).css("display", "inline-block");
        $("#inputvaluemarge" + id).css("display", "none");
        $(".radiovalueMarge" + id).css("display", "none");
        this.RelierAuCalcule = "non";
      }
      if (who === "valeurBrut") {
        $("#buttonTryEditvaleurBrut" + id).css("display", "inline-block");
        $("#buttonAnnulerEditvaleurBrut" + id).css("display", "none");
        $("#buttonValideEditvaleurBrut" + id).css("display", "none");
        $("#valuevaleurBrut" + id).css("display", "inline-block");
        $("#inputvaluevaleurBrut" + id).css("display", "none");
        $(".radiovaluevaleurBrut" + id).css("display", "none");
        this.RelierAuCalcule = "non";
      }
      if (who === "duree") {
        $("#buttonTryEditduree" + id).css("display", "inline-block");
        $("#buttonAnnulerEditduree" + id).css("display", "none");
        $("#buttonValideEditduree" + id).css("display", "none");
        $("#valueduree" + id).css("display", "inline-block");
        $("#inputduree" + id).css("display", "none");
        $(".radioduree" + id).css("display", "none");
        this.RelierAuCalcule = "non";
      }
      if (who === "pourcentage") {
        $("#buttonTryEditpourcentage" + id).css("display", "inline-block");
        $("#buttonAnnulerEditpourcentage" + id).css("display", "none");
        $("#buttonValideEditpourcentage" + id).css("display", "none");
        $("#valuepourcentage" + id).css("display", "inline-block");
        $("#inputvaluepourcentage" + id).css("display", "none");
        $(".radiovaluepourcentage" + id).css("display", "none");
        this.RelierAuCalcule = "non";
      }
      if (who === "date") {
        $("#buttonTryEditdate" + id).css("display", "inline-block");
        $("#buttonAnnulerEditdate" + id).css("display", "none");
        $("#buttonValideEditdate" + id).css("display", "none");
        $("#valuedate" + id).css("display", "inline-block");
        $("#inputValuedate" + id).css("display", "none");
        this.RelierAuCalcule = "non";
      }
    },
    async modifierValue(who, id) {
      if (who === "value") {
        var val = $("#inputValue" + id).val();
        var formData = new FormData();
        formData.append("token", this.getToken("token"));
        formData.append("val", val);
        formData.append("idVente", id);
        await axios({
          url: process.env.VUE_APP_URL_API_CLIENT + "updateValueAAVentePro/",
          method: "POST",
          data: formData,
        })
          .then((res) => {
            if (res.data.result == true) {
              alert("la valeur de cette vente a été mise à jour");
              var valeur = res.data.valeurAA;
              this.filterResult({ numberOfPage: this.pagination.currentPage });
              this.annulerUpdateValue("value", id);
            } else if (res.data.result == false) {
              alert("vérifiez que votre valeur soit valide");
              this.filterResult({ numberOfPage: this.pagination.currentPage });
            } else if (res.data.result == "permission") {
              alert("permission refusée");
              localStorage.removeItem("vendeurName");
              localStorage.removeItem("typeUser");
              localStorage.removeItem("token");
              this.$router.push("/login");
            }
          })
          .catch((err) => {
            alert("permission refusée");
          });
      }
      if (who === "consommation") {
        var val = $("#inputConsommation" + id).val();

        var formData = new FormData();
        formData.append("token", this.getToken("token"));
        formData.append("val", val);
        formData.append("RelierAuCalcule", this.RelierAuCalcule);
        formData.append("idVente", id);
        await axios({
          url: process.env.VUE_APP_URL_API_CLIENT + "updateConsommationVentePro/",
          method: "POST",
          data: formData,
        })
          .then((res) => {
            if (res.data.result == true) {
              alert("la valeur de cette vente a été mise à jour");
              var pourcentage = res.data.pourcentage;
              var valeurBrut = res.data.valeurBrut;
              var valeur = res.data.valeur;
              var valeurGemc = res.data.valeurGemc;
              var marge = res.data.marge;
              var Consommation = res.data.Consommation;
              this.filterResult({ numberOfPage: this.pagination.currentPage });
              this.annulerUpdateValue("consommation", id);
            } else if (res.data.result == false) {
              alert("vérifiez que votre valeur soit valide");
              this.filterResult({ numberOfPage: this.pagination.currentPage });
            } else if (res.data.result == "permission") {
              alert("permission refusée");
              localStorage.removeItem("vendeurName");
              localStorage.removeItem("typeUser");
              localStorage.removeItem("token");
              this.$router.push("/login");
            }
          })
          .catch((err) => {
            alert("permission refusée");
          });
      }
      if (who === "valueGEMC") {
        var val = $("#inputvalueGEMC" + id).val();

        var formData = new FormData();
        formData.append("token", this.getToken("token"));
        formData.append("val", val);
        formData.append("idVente", id);
        await axios({
          url: process.env.VUE_APP_URL_API_CLIENT + "updateAutreValueVentePro/",
          method: "POST",
          data: formData,
        })
          .then((res) => {
            if (res.data.result == true) {
              alert("la valeur de cette vente a été mise à jour");
              var valeurGemc = res.data.valeurGemc;
              this.filterResult({ numberOfPage: this.pagination.currentPage });
              this.annulerUpdateValue("valueGEMC", id);
            } else if (res.data.result == false) {
              alert("vérifiez que votre valeur soit valide");
              this.filterResult({ numberOfPage: this.pagination.currentPage });
            } else if (res.data.result == "permission") {
              alert("permission refusée");
              localStorage.removeItem("vendeurName");
              localStorage.removeItem("typeUser");
              localStorage.removeItem("token");
              this.$router.push("/login");
            }
          })
          .catch((err) => {
            alert("permission refusée");
          });
      }
      if (who === "valuemarge") {
        var val = $("#inputvaluemarge" + id).val();

        var formData = new FormData();
        formData.append("token", this.getToken("token"));
        formData.append("val", val);
        formData.append("RelierAuCalcule", this.RelierAuCalcule);
        formData.append("idVente", id);
        await axios({
          url: process.env.VUE_APP_URL_API_CLIENT + "updateMargeVentePro/",
          method: "POST",
          data: formData,
        })
          .then((res) => {
            if (res.data.result == true) {
              alert("la valeur de cette vente a été mise à jour");
              var pourcentage = res.data.pourcentage;
              var valeurBrut = res.data.valeurBrut;
              var valeur = res.data.valeur;
              var valeurGemc = res.data.valeurGemc;
              var marge = res.data.marge;
              var Consommation = res.data.Consommation;
              this.filterResult({ numberOfPage: this.pagination.currentPage });
              this.annulerUpdateValue("valuemarge", id);
            } else if (res.data.result == false) {
              alert("vérifiez que votre valeur soit valide");
              this.filterResult({ numberOfPage: this.pagination.currentPage });
            } else if (res.data.result == "permission") {
              alert("permission refusée");
              localStorage.removeItem("vendeurName");
              localStorage.removeItem("typeUser");
              localStorage.removeItem("token");
              this.$router.push("/login");
            }
          })
          .catch((err) => {
            alert("permission refusée");
          });
      }
      if (who === "valeurBrut") {
        var val = $("#inputvaluevaleurBrut" + id).val();

        var formData = new FormData();
        formData.append("token", this.getToken("token"));
        formData.append("val", val);
        formData.append("RelierAuCalcule", this.RelierAuCalcule);
        formData.append("idVente", id);
        await axios({
          url: process.env.VUE_APP_URL_API_CLIENT + "updateValeurBrut/",
          method: "POST",
          data: formData,
        })
          .then(async (res) => {
            if (res.data.result == true) {
              alert("la valeur de cette vente a été mise à jour");
              var pourcentage = res.data.pourcentage;
              var valeurBrut = res.data.valeurBrut;
              var valeur = res.data.valeur;
              var valeurGemc = res.data.valeurGemc;
              var marge = res.data.marge;
              var Consommation = res.data.Consommation;
              console.log(this.pagination.currentPage);
              await this.filterResult({ numberOfPage: this.pagination.currentPage });
              this.annulerUpdateValue("valeurBrut", id);
            } else if (res.data.result == false) {
              alert("vérifiez que votre valeur soit valide");
              this.filterResult({ numberOfPage: this.pagination.currentPage });
            } else if (res.data.result == "permission") {
              localStorage.removeItem("vendeurName");
              localStorage.removeItem("typeUser");
              localStorage.removeItem("token");
              this.$router.push("/login");
            }
          })
          .catch((err) => {
            alert("permission refusée");
          });
      }
      if (who === "duree") {
        var val = $("#inputduree" + id).val();
        var formData = new FormData();
        formData.append("token", this.getToken("token"));
        formData.append("val", val);
        formData.append("RelierAuCalcule", this.RelierAuCalcule);
        formData.append("idVente", id);
        await axios({
          url: process.env.VUE_APP_URL_API_CLIENT + "updateDuree/",
          method: "POST",
          data: formData,
        })
          .then((res) => {
            if (res.data.result == true) {
              alert("la valeur de cette vente a été mise à jour");
              var pourcentage = res.data.pourcentage;
              var valeurBrut = res.data.valeurBrut;
              var valeur = res.data.valeur;
              var valeurGemc = res.data.valeurGemc;
              var marge = res.data.marge;
              var Consommation = res.data.Consommation;
              this.filterResult({ numberOfPage: this.pagination.currentPage });
              this.annulerUpdateValue("duree", id);
            } else if (res.data.result == false) {
              alert("vérifiez que votre valeur soit valide");
              this.filterResult({ numberOfPage: this.pagination.currentPage });
            } else if (res.data.result == "permission") {
              localStorage.removeItem("vendeurName");
              localStorage.removeItem("typeUser");
              localStorage.removeItem("token");
              this.$router.push("/login");
            }
          })
          .catch((err) => {
            alert("permission refusée");
          });
      }
      if (who === "pourcentage") {
        var val = $("#inputvaluepourcentage" + id).val();
        var formData = new FormData();
        formData.append("token", this.getToken("token"));
        formData.append("val", val);
        formData.append("RelierAuCalcule", this.RelierAuCalcule);
        console.log(this.RelierAuCalcule);
        formData.append("idVente", id);
        axios({
          url: process.env.VUE_APP_URL_API_CLIENT + "updatePourcentageVentePro/",
          method: "POST",
          data: formData,
        })
          .then((res) => {
            if (res.data.result == true) {
              alert("la valeur de cette vente a été mise à jour");
              var pourcentage = res.data.pourcentage;
              var valeurBrut = res.data.valeurBrut;
              var valeur = res.data.valeur;
              var valeurGemc = res.data.valeurGemc;
              var marge = res.data.marge;
              var Consommation = res.data.Consommation;
              if (this.items["Consommation"].indexOf(Consommation) == -1) {
                this.items["Consommation"].push(Consommation);
                this.filterItem["Consommation"].push(Consommation);
              } else if (this.filterItem["Consommation"].indexOf(Consommation) == -1) {
                this.filterItem["Consommation"].push(Consommation);
              }
              if (this.items["Pourcentage"].indexOf(pourcentage) == -1) {
                this.items["Pourcentage"].push(pourcentage);
                this.filterItem["Pourcentage"].push(pourcentage);
              } else if (this.filterItem["Pourcentage"].indexOf(pourcentage) == -1) {
                this.filterItem["Pourcentage"].push(pourcentage);
              }
              if (this.items["valeurBrut"].indexOf(valeurBrut) == -1) {
                this.items["valeurBrut"].push(valeurBrut);
                this.filterItem["valeurBrut"].push(valeurBrut);
              } else if (this.filterItem["valeurBrut"].indexOf(valeurBrut) == -1) {
                this.filterItem["valeurBrut"].push(valeurBrut);
              }
              if (this.items["valeurAA"].indexOf(valeur) == -1) {
                this.items["valeurAA"].push(valeur);
                this.filterItem["valeurAA"].push(valeur);
              } else if (this.filterItem["valeurAA"].indexOf(valeur) == -1) {
                this.filterItem["valeurAA"].push(valeur);
              }
              if (this.items["valeurGEMC"].indexOf(valeurGemc) == -1) {
                this.items["valeurGEMC"].push(valeurGemc);
                this.filterItem["valeurGEMC"].push(valeurGemc);
              } else if (this.filterItem["valeurGEMC"].indexOf(valeurGemc) == -1) {
                this.filterItem["valeurGEMC"].push(valeurGemc);
              }
              if (this.items["marge"].indexOf(marge) == -1) {
                this.items["marge"].push(marge);
                this.filterItem["marge"].push(marge);
              } else if (this.filterItem["marge"].indexOf(marge) == -1) {
                this.filterItem["marge"].push(marge);
              }
              this.filterResult({ numberOfPage: this.pagination.currentPage });
              this.annulerUpdateValue("pourcentage", id);
            } else if (res.data.result == false) {
              alert("vérifiez que votre valeur soit valide");
              this.filterResult({ numberOfPage: this.pagination.currentPage });
            } else if (res.data.result == "permission") {
              alert("permission refusée");
              localStorage.removeItem("vendeurName");
              localStorage.removeItem("typeUser");
              localStorage.removeItem("token");
              this.$router.push("/login");
            }
          })
          .catch((err) => {
            alert("permission refusée");
          });
      }
      if (who === "date") {
        var val = $("#inputValuedate" + id).val();
        val = this.dateFormatSlach(val);
        if (!this.validateDate(val)) {
          return null;
        }
        var formData = new FormData();
        formData.append("token", this.getToken("token"));
        formData.append("val", val);
        formData.append("idVente", id);
        axios({
          url: process.env.VUE_APP_URL_API_CLIENT + "updateDateVentePro/",
          method: "POST",
          data: formData,
        })
          .then((res) => {
            if (res.data.result == true) {
              alert("la date de cette vente a été mise à jour");
              this.filterResult({ numberOfPage: this.pagination.currentPage });
              this.annulerUpdateValue("date", id);
            } else if (res.data.result == false) {
              alert("vérifiez que votre valeur soit valide");
              this.filterResult({ numberOfPage: this.pagination.currentPage });
            } else if (res.data.result == "permission") {
              alert("permission refusée");
              localStorage.removeItem("vendeurName");
              localStorage.removeItem("typeUser");
              localStorage.removeItem("token");
              this.$router.push("/login");
            }
          })
          .catch((err) => {
            alert("permission refusée");
          });
      }
    },
    async filterResult({ type, key = "", items = "None", numberOfPage = 0 }) {
      if (
        type == "search" ||
        type == "nbrRecords" ||
        type == "filter" ||
        type == "sort" ||
        type == "next"
      ) {
        this.tableData.filterType = type;
      }
      var pageNumber = 0;
      if (type == "search") {
        var pageNumber = 0;
        this.tableData.search = key;
      } else if (type == "nbrRecords") {
        this.tableData.search = this.search;
        this.tableData.length = key;
      } else if (type == "filter") {
        this.tableData.search = this.search;
        this.tableData.filterItems = items;
        this.tableData.filterItems.dateDebutFMIN = this.filterItem.dateDebutFMIN;
        this.tableData.filterItems.dateDebutFMAX = this.filterItem.dateDebutFMAX;
      } else if (type == "sort") {
        this.tableData.search = this.search;
      } else if (type == "next") {
        this.tableData.search = this.search;
        pageNumber = numberOfPage;
      } else {
        this.tableData.search = this.search;
        pageNumber = numberOfPage;
      }
      this.tableData.draw++;
      await axios({
        url: process.env.VUE_APP_URL_API_CLIENT + "filterRapportVentePro/",
        method: "POST",
        data: {
          dataTable: this.tableData,
          pageNumber: pageNumber,
          type: this.tableData.filterType,
          itemsFilter: this.tableData.filterItems,
          token: this.getToken("token"),
        },
      })
        .then((response) => {
          if (response.data.result == true) {
            let data = response.data.data;
            if (this.tableData.draw == data.draw) {
              this.projects = data.data.data;
              this.configPagination(data.data);
            }
          } else {
            alert("permission refusée");
            localStorage.removeItem("vendeurName");
            localStorage.removeItem("typeUser");
            localStorage.removeItem("token");
            this.$router.push("/login");
          }
        })
        .catch((errors) => {
          console.log(errors);
        });
    },

    rechercheData({ type, key, searchItem = "None" }) {
      if (type == "search") {
        var pageNumber = 0;
        this.tableData.search = key;
      } else if (type == "nbrRecords") {
        this.tableData.length = key;
      }
      var pageNumber = 0;
      this.tableData.search = key;
      this.tableData.draw++;
      axios({
        url: process.env.VUE_APP_URL_API_CLIENT + "getVenteProServerSideRapportDeVente/",
        method: "POST",
        data: {
          dataTable: this.tableData,
          pageNumber: pageNumber,
          searchItem: searchItem,
          token: this.getToken("token"),
        },
      })
        .then((response) => {
          if (response.data.result == true) {
            let data = response.data.data;
            this.items["nom"] = response.data.nom;
            this.items["raison"] = response.data.raison;
            this.items["fournisseur"] = response.data.fournisseur;
            this.items["type"] = response.data.type;
            this.items["status"] = response.data.listevaleurstatus;
            if (this.tableData.draw == data.draw) {
              this.projects = data.data.data;
              this.configPagination(data.data);
            }
          } else {
            alert("permission refusée");
            localStorage.removeItem("vendeurName");
            localStorage.removeItem("typeUser");
            localStorage.removeItem("token");
            this.$router.push("/login");
          }
        })
        .catch((errors) => {
          console.log(errors);
        });
    },
    /*ouvrireDialogeRefuserVente(item){
                this.VenteRefuser = item
                this.ouvrireDialogeRefuser = true
            },*/
    /*fermerDialogeRefuserVente(){
                this.VenteRefuser = ''
                this.ouvrireDialogeRefuser = false
            },*/
    /*ouvrireDialogeSupprimerVente(item){
                this.VenteSupprimer = item
                this.ouvrireDialogeSupprimer = true
            },*/
    /*fermerDialogeSupprimerVente(){
                this.VenteSupprimer = ''
                this.ouvrireDialogeSupprimer = false
            },*/
    async getData(pageNumber = 0) {
      this.tableData.draw++;
      await axios({
        url: process.env.VUE_APP_URL_API_CLIENT + "getVenteProServerSideRapportDeVente/",
        method: "POST",
        data: {
          dataTable: this.tableData,
          pageNumber: pageNumber,
          searchItem: "None",
          token: this.getToken("token"),
        },
      })
        .then((response) => {
          if (response.data.result == true) {
            let data = response.data.data;
            this.items["nom"] = response.data.nom;
            this.items["raison"] = response.data.raison;
            this.items["fournisseur"] = response.data.fournisseur;
            this.items["type"] = response.data.type;
            this.items["status"] = response.data.listevaleurstatus;
            this.filterItem = { ...this.items };
            if (this.tableData.draw == data.draw) {
              this.projects = data.data.data;
              this.configPagination(data.data);
            }
          } else {
            alert("permission refusée");
            localStorage.removeItem("vendeurName");
            localStorage.removeItem("typeUser");
            localStorage.removeItem("token");
            this.$router.push("/login");
          }
        })
        .catch((errors) => {
          console.log(errors);
        });
    },
    configPagination(data) {
      this.pagination.lastPage = data.last_page;
      this.pagination.currentPage = data.current_page;
      this.pagination.total = data.total;
      this.pagination.lastPageUrl = data.last_page_url;
      this.pagination.nextPageUrl = data.next_page_url;
      this.pagination.prevPageUrl = data.prev_page_url;
      this.pagination.from = data.from;
      this.pagination.to = data.to;
    },
    sortBy(key) {
      this.sortKey = key;
      this.sortOrders[key] = this.sortOrders[key] * -1;
      this.tableData.column = this.getIndex(this.columns, "name", key);
      this.tableData.dir = this.sortOrders[key] === 1 ? "asc" : "desc";
      this.filterResult({ type: "sort" });
    },
    getIndex(array, key, value) {
      return array.findIndex((i) => i[key] == value);
    },
    async changeStatut(item, newStatus) {
      var formData = new FormData();
      formData.append("newStatus", newStatus);
      formData.append("id", item.id);
      formData.append("token", this.getToken("token"));
      await axios({
        url: process.env.VUE_APP_URL_API_CLIENT + "changeStatut/",
        method: "POST",
        data: formData,
      })
        .then(async (res) => {
          if (res.data.result == true) {
            await this.getData(this.pagination.currentPage);
          } else {
            alert("permission refusée");
            localStorage.removeItem("vendeurName");
            localStorage.removeItem("typeUser");
            localStorage.removeItem("token");
            this.$router.push("/login");
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    deleteRow(deletedItem) {},
  },
};
window.onclick = function (event) {
  if (event.target.className == "td" || event.target.className == "v-btn__content") {
    $(".dropdown-content").css("display", "none");
  }
};
</script>
<style scoped>
.td-table,
.d1 {
  display: flex;
  justify-content: center;
}
@media only screen and (max-width: 350px) {
  .search-input {
    width: 125px !important;
  }
}
</style>
