<template>
  <v-form ref="formDetaillesCompteur" @submit.prevent="submitForm">
    <template>
      <v-subheader class="mt-12">détails de Compteur:</v-subheader>
      <v-divider class="mb-12"></v-divider>
        <v-row>
          <v-col md="12" lg="12" xl="12" cols="12" v-if="dialog==true">
              <v-dialog
                  v-model="dialog"
                  persistent
                  max-width="500"
                  >
                  <v-card>
                      <v-card-title class="text-h5">
                      contrôle du compteur
                      </v-card-title>
                      <v-card-text>
                          <v-alert v-if="error==true && loading==false" type="error">
                              {{messageDialoge}}
                          </v-alert>
                          <v-alert v-if="error==false && loading==false" type="success">
                              {{messageDialoge}}
                          </v-alert>
                          <v-flex  class="text-center">
                              <v-progress-circular v-if="loading==true"
                                  :size="150"
                                  :width="7"
                                  color="primary"
                                  indeterminate
                                  >
                                  Patientez  ...
                              </v-progress-circular>
                          </v-flex>
                      </v-card-text>
                      <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn v-if="loading==false"
                          color="green darken-1"
                          text
                          @click="dialog = false"
                      >
                          FERMER
                      </v-btn>
                      </v-card-actions>
                  </v-card>
              </v-dialog>
          </v-col>
        <!-- Numéro du compteur -->
        <v-col sm="6" md="4" lg="5">
          <v-select
            @change="handleComteurChange"
            required
            outlined
            v-model="editedForm.compteur"
            :items="listCompteurs"
            label="Numéro de compteur"
            :rules="[required('le Numéro de compteur')]"
          ></v-select>
        </v-col>
        <v-col xs="12" sm="3" md="3" lg="2" class="d-flex justify-end">
          <v-btn
            @click="toggleFormeFournisseur"
            elevation="1"
            large
            :color="isInfoFurnisseur ? null : 'primary'"
            style="textTransform: none"
            width="75%"
          >
            <v-icon left>
              {{ isInfoFurnisseur ? 'mdi-minus' : 'mdi-plus' }}
            </v-icon>
            Fournisseur
          </v-btn>
        </v-col>
      </v-row>

      <v-row v-if="editedForm.compteur">
        <!-- La date de début -->
        <v-col sm="4" md="4" lg="4">
          <v-text-field 
            type="date" 
            outlined
            :rules="[required('la Date Début de Contrat'), 
                    isValidDate('D/M/YYYY', minDebutContrat, maxDateDebutContrat)]"
            label="Date Début de Contrat"
            v-model="editedForm.detaillesContrat.dateDebutContrat"
            :min="minDebutContrat"
            :max="maxDateDebutContrat"/>
        </v-col>

        <!-- La date de fin -->
        <v-col sm="4" md="4" lg="4">
              <v-text-field 
                type="date" 
                outlined
                :rules="[required('la Date Fin de Contrat'), 
                        isValidDate('D/M/YYYY', minDebutFinContrat, maxDateFinContrat)]"
                label="Date Fin de Contrat"
                v-model="editedForm.detaillesContrat.dateFinContrat"
                :min="minDebutFinContrat"
                :max="maxDateFinContrat"/>
        </v-col>
      </v-row>

      <v-row  v-if="editedForm.compteur">
        <!--Option Tarifaire-->
        <v-col sm="4" md="4" lg="3">
          <v-select
            required
            outlined
            v-model="editedForm.detaillesContrat.optionTarifaire"
            :items="optionsTarifaires"
            label="Option Tarifaire"
            :disabled="isDisabledTechnique"
            :rules="[required('l\'Option Tatifaire ')]"
          ></v-select>
        </v-col>
        <!-- Marge -->
        <v-col sm="4" md="4" lg="3">
          <v-text-field
            type="number"
            step="0.001"
            max="25"
            min="2"
            required
            outlined
            v-model="editedForm.detaillesContrat.marge"
            label="Marge"
            :rules="[required('une marge'), rules.margeMaxMin]"
          ></v-text-field>
        </v-col>

        <!--Puissance souscrite avec options à sélectionner -->
        <v-col sm="6" md="4" lg="3">
          <v-text-field
            required
            outlined
            v-model="editedForm.detaillesContrat.puissanceSouscrite"
            :items="optionsPuissance"
            label="Puissance souscrite"
            suffix="kVA"
            :disabled="isDisabledTechnique"
            :rules="[required('la Puisance souscrite')]"
          ></v-text-field>
        </v-col>
      </v-row>
      <!-- Consommations P, HP, HC -->
      <v-row>
        <!-- Consommation (P) -->
        <v-col
          sm="6"
          md="4"
          lg="3"
          v-if="
            ['HTACU5', 'HTACUPM5', 'HTALU5', 'HTALUPM5'].includes(
              editedForm.detaillesContrat.optionTarifaire
            )
          "
        >
          <v-text-field
            required
            outlined
            type="number"
            v-model="editedForm.detaillesContrat.consoP"
            label="Consommation (P)"
            suffix="MWh"
            step="0.01"
            min="0"
            :disabled="isDisabledMesures"
            :rules="[required('la Consommation (P)')]"
          ></v-text-field>
        </v-col>
        <!-- Consommation (HP) -->
        <v-col
          sm="6"
          md="4"
          lg="3"
          v-if="
            ['BTINFMUDT'].includes(editedForm.detaillesContrat.optionTarifaire)
          "
        >
          <v-text-field
            required
            outlined
            type="number"
            v-model="editedForm.detaillesContrat.consoAHP"
            label="Consommation (HP)"
            suffix="MWh"
            step="0.01"
            min="0"
            :disabled="isDisabledMesures"
            :rules="[required('la Consommation (HP)')]"
          ></v-text-field>
        </v-col>
        <!-- Consommation (HC) -->
        <v-col
          sm="6"
          md="4"
          lg="3"
          v-if="
            ['BTINFMUDT'].includes(editedForm.detaillesContrat.optionTarifaire)
          "
        >
          <v-text-field
            required
            outlined
            type="number"
            v-model="editedForm.detaillesContrat.consoAHC"
            label="Consommation (HC)"
            suffix="MWh"
            step="0.01"
            min="0"
            :disabled="isDisabledMesures"
            :rules="[required('la Consommation (HC)')]"
          ></v-text-field>
        </v-col>
      </v-row>

      <!-- Consommations HPH, HCH, HPB, HPC -->
      <v-row>
        <!-- Consommation (HPH) -->
        <v-col
          sm="6"
          md="4"
          lg="3"
          v-if="
            [
              'BTINFCU4',
              'BTINFMU4',
              'BTSUPCU4',
              'BTSUPLU4',
              'HTACU5',
              'HTACUPM5',
              'HTALU5',
              'HTALUPM5',
            ].includes(editedForm.detaillesContrat.optionTarifaire)
          "
        >
          <v-text-field
            required
            outlined
            type="number"
            v-model="editedForm.detaillesContrat.consoAHPH"
            label="Consommation (HPH)"
            suffix="MWh"
            step="0.01"
            min="0"
            :disabled="isDisabledMesures"
            :rules="[required('la Consommation (HPH)')]"
          ></v-text-field>
        </v-col>

        <!-- Consommation (HCH) -->
        <v-col
          sm="6"
          md="4"
          lg="3"
          v-if="
            [
              'BTINFCU4',
              'BTINFMU4',
              'BTSUPCU4',
              'BTSUPLU4',
              'HTACU5',
              'HTACUPM5',
              'HTALU5',
              'HTALUPM5',
            ].includes(editedForm.detaillesContrat.optionTarifaire)
          "
        >
          <v-text-field
            required
            outlined
            type="number"
            v-model="editedForm.detaillesContrat.consoAHCH"
            label="Consommation (HCH)"
            suffix="MWh"
            step="0.01"
            :disabled="isDisabledMesures"
            :rules="[required('la Consommation (HCH)')]"
          ></v-text-field>
        </v-col>

        <!-- Consommation (HPB) -->
        <v-col
          sm="6"
          md="4"
          lg="3"
          v-if="
            [
              'BTINFCU4',
              'BTINFMU4',
              'BTSUPCU4',
              'BTSUPLU4',
              'HTACU5',
              'HTACUPM5',
              'HTALU5',
              'HTALUPM5',
            ].includes(editedForm.detaillesContrat.optionTarifaire)
          "
        >
          <v-text-field
            required
            outlined
            type="number"
            v-model="editedForm.detaillesContrat.consoAHPB"
            label="Consommation (HPE)"
            suffix="MWh"
            step="0.01"
            min="-1"
            :disabled="isDisabledMesures"
            :rules="[required('la Consommation (HPE)')]"
          ></v-text-field>
        </v-col>

        <!-- Consommation (HCB) -->
        <v-col
          sm="6"
          md="4"
          lg="3"
          v-if="
            [
              'BTINFCU4',
              'BTINFMU4',
              'BTSUPCU4',
              'BTSUPLU4',
              'HTACU5',
              'HTACUPM5',
              'HTALU5',
              'HTALUPM5',
            ].includes(editedForm.detaillesContrat.optionTarifaire)
          "
        >
          <v-text-field
            required
            outlined
            type="number"
            v-model="editedForm.detaillesContrat.consoAHCB"
            label="Consommation (HCE)"
            suffix="MWh"
            step="0.01"
            min="-1"
            :disabled="isDisabledMesures"
            :rules="[required('la Consommation (HCE)')]"
          ></v-text-field>
        </v-col>
      </v-row>

      <!-- Consommation annuelle -->
      <v-row>
        <v-col
          sm="6"
          md="4"
          lg="3"
          v-if="
            ['BTINFCUST', 'BTINFLU'].includes(
              editedForm.detaillesContrat.optionTarifaire
            )
          "
        >
          <v-text-field
            required
            outlined
            type="number"
            v-model="editedForm.detaillesContrat.consoA"
            label="Consommation Annuelle"
            suffix="MWh"
            step="0.01"
            :disabled="isDisabledMesures"
            :rules="[required('la Consommation Annuelle')]"
          ></v-text-field>
        </v-col>
      </v-row>
    </template>

    <template v-if="isInfoFurnisseur">
      <v-subheader class="mt-12"
        >Informations de Fournisseur Actuel:</v-subheader
      >
      <v-divider class="mb-12"></v-divider>

      <!--Forme Fournisseur Actuel ------------------------------------------------------------------------------------------------------------------------------------>
      <!-- ------------------------ ---------------------------------------------------------------------------------------------------------------------------------- -->

      <v-row>
        <!--Option Fournisseur-->
        <v-col sm="4" md="4" lg="3">
          <v-select
            required
            outlined
            v-model="editedForm.detaillesFournisseur.fournisseur"
            :items="optionsFournisseurs"
            label="Fournisseur Actuel"
          ></v-select>
        </v-col>
        <v-col sm="6" md="4" lg="4" class="d-flex justify-center">
          <v-radio-group
            row
            v-model="editedForm.detaillesFournisseur.optionCapacite"
          >
            <v-radio
              key="coef"
              label="Coefficient Capacité"
              value="coef"
              checked
            ></v-radio>
            <v-radio key="capa" label="Coût Capacité" value="capa"></v-radio>
          </v-radio-group>
        </v-col>
      </v-row>

      <v-row>
        <!--Coût (P) -->
        <v-col
          sm="6"
          md="4"
          lg="3"
          v-if="
            ['HTACU5', 'HTACUPM5', 'HTALU5', 'HTALUPM5'].includes(
              editedForm.detaillesContrat.optionTarifaire
            )
          "
        >
          <v-text-field
            required
            outlined
            type="number"
            v-model="editedForm.detaillesFournisseur.coutP"
            label="Coût (P)"
            suffix="€"
            step="0.001"
            min="0"
          ></v-text-field>
        </v-col>

        <!-- Coût (HP) -->
        <v-col
          sm="6"
          md="4"
          lg="3"
          v-if="
            ['BTINFMUDT'].includes(editedForm.detaillesContrat.optionTarifaire)
          "
        >
          <v-text-field
            required
            outlined
            type="number"
            v-model="editedForm.detaillesFournisseur.coutHP"
            label="Coût(HP)"
            suffix="€"
            step="0.001"
            min="0"
          ></v-text-field>
        </v-col>

        <!-- Coût (HC) -->
        <v-col
          sm="6"
          md="4"
          lg="3"
          v-if="
            ['BTINFMUDT'].includes(editedForm.detaillesContrat.optionTarifaire)
          "
        >
          <v-text-field
            required
            outlined
            type="number"
            v-model="editedForm.detaillesFournisseur.coutHC"
            label="Coût(HC)"
            suffix="€"
            step="0.001"
            min="0"
          ></v-text-field>
        </v-col>

        <!-- Coût HPH, HCH, HPB, HPC -->

        <!-- Coût (HPH) -->
        <v-col
          sm="6"
          md="4"
          lg="3"
          v-if="
            [
              'BTINFCU4',
              'BTINFMU4',
              'BTSUPCU4',
              'BTSUPLU4',
              'HTACU5',
              'HTACUPM5',
              'HTALU5',
              'HTALUPM5',
            ].includes(editedForm.detaillesContrat.optionTarifaire)
          "
        >
          <v-text-field
            required
            outlined
            type="number"
            v-model="editedForm.detaillesFournisseur.coutHPH"
            label="Coût(HPH)"
            suffix="€"
            step="0.001"
            min="0"
          ></v-text-field>
        </v-col>

        <!-- Coût (HCH) -->
        <v-col
          sm="6"
          md="4"
          lg="3"
          v-if="
            [
              'BTINFCU4',
              'BTINFMU4',
              'BTSUPCU4',
              'BTSUPLU4',
              'HTACU5',
              'HTACUPM5',
              'HTALU5',
              'HTALUPM5',
            ].includes(editedForm.detaillesContrat.optionTarifaire)
          "
        >
          <v-text-field
            required
            outlined
            type="number"
            v-model="editedForm.detaillesFournisseur.coutHCH"
            label="Coût(HCH)"
            suffix="€"
            step="0.001"
            min="0"
          ></v-text-field>
        </v-col>

        <!-- Coût (HPB) -->
        <v-col
          sm="6"
          md="4"
          lg="3"
          v-if="
            [
              'BTINFCU4',
              'BTINFMU4',
              'BTSUPCU4',
              'BTSUPLU4',
              'HTACU5',
              'HTACUPM5',
              'HTALU5',
              'HTALUPM5',
            ].includes(editedForm.detaillesContrat.optionTarifaire)
          "
        >
          <v-text-field
            required
            outlined
            type="number"
            v-model="editedForm.detaillesFournisseur.coutHPB"
            label="Coût(HPB)"
            suffix="€"
            step="0.001"
            min="0"
          ></v-text-field>
        </v-col>

        <!-- Coût (HCB) -->
        <v-col
          sm="6"
          md="4"
          lg="3"
          v-if="
            [
              'BTINFCU4',
              'BTINFMU4',
              'BTSUPCU4',
              'BTSUPLU4',
              'HTACU5',
              'HTACUPM5',
              'HTALU5',
              'HTALUPM5',
            ].includes(editedForm.detaillesContrat.optionTarifaire)
          "
        >
          <v-text-field
            required
            outlined
            type="number"
            v-model="editedForm.detaillesFournisseur.coutHCB"
            label="Coût(HCB)"
            suffix="€"
            step="0.001"
            min="0"
          ></v-text-field>
        </v-col>

        <!-- Coût Base -->
        <v-col
          sm="6"
          md="4"
          lg="3"
          v-if="
            ['BTINFCUST', 'BTINFLU'].includes(
              editedForm.detaillesContrat.optionTarifaire
            )
          "
        >
          <v-text-field
            required
            outlined
            type="number"
            v-model="editedForm.detaillesFournisseur.coutBase"
            label="Coût Base"
            suffix="€"
            step="0.001"
            min="0"
          ></v-text-field>
        </v-col>
      </v-row>

      <!-- Coefficients Option Tarifaire / optionCapacite == 'coef' -->
      <v-row v-if="editedForm.detaillesFournisseur.optionCapacite == 'coef'">
        <!-- Coefficient (C-P) -->
        <v-col
          sm="6"
          md="4"
          lg="3"
          v-if="
            ['HTACU5', 'HTACUPM5', 'HTALU5', 'HTALUPM5'].includes(
              editedForm.detaillesContrat.optionTarifaire
            )
          "
        >
          <v-text-field
            required
            outlined
            type="number"
            v-model="editedForm.detaillesFournisseur.coefP"
            label="Coefficient (C-P)"
            step="0.001"
            min="0"
          ></v-text-field>
        </v-col>

        <!-- Coefficient (C-HP) -->
        <v-col
          sm="6"
          md="4"
          lg="3"
          v-if="
            ['BTINFMUDT'].includes(editedForm.detaillesContrat.optionTarifaire)
          "
        >
          <v-text-field
            required
            outlined
            type="number"
            v-model="editedForm.detaillesFournisseur.coefHP"
            label="Coefficient (C-HP)"
            step="0.001"
            min="0"
          ></v-text-field>
        </v-col>

        <!-- Coefficient (C-HC) -->
        <v-col
          sm="6"
          md="4"
          lg="3"
          v-if="
            ['BTINFMUDT'].includes(editedForm.detaillesContrat.optionTarifaire)
          "
        >
          <v-text-field
            required
            outlined
            type="number"
            v-model="editedForm.detaillesFournisseur.coefHC"
            label="Coefficient (C-HC)"
            step="0.001"
            min="0"
          ></v-text-field>
        </v-col>

        <!-- Coefficients (C-HPH,C-HCH,C-HPB,C-HPC) -->
        <!-- Coefficient (C-HPH) -->
        <v-col
          sm="6"
          md="4"
          lg="3"
          v-if="
            [
              'BTINFCU4',
              'BTINFMU4',
              'BTSUPCU4',
              'BTSUPLU4',
              'HTACU5',
              'HTACUPM5',
              'HTALU5',
              'HTALUPM5',
            ].includes(editedForm.detaillesContrat.optionTarifaire)
          "
        >
          <v-text-field
            required
            outlined
            type="number"
            v-model="editedForm.detaillesFournisseur.coefHPH"
            label="Coefficient (C-HPH)"
            step="0.001"
            min="0"
          ></v-text-field>
        </v-col>

        <!-- Coefficient (C-HCH) -->
        <v-col
          sm="6"
          md="4"
          lg="3"
          v-if="
            [
              'BTINFCU4',
              'BTINFMU4',
              'BTSUPCU4',
              'BTSUPLU4',
              'HTACU5',
              'HTACUPM5',
              'HTALU5',
              'HTALUPM5',
            ].includes(editedForm.detaillesContrat.optionTarifaire)
          "
        >
          <v-text-field
            required
            outlined
            type="number"
            v-model="editedForm.detaillesFournisseur.coefHCH"
            label="Coefficient (C-HCH)"
            step="0.001"
            min="0"
          ></v-text-field>
        </v-col>

        <!-- Coefficient (C-HPB) -->
        <v-col
          sm="6"
          md="4"
          lg="3"
          v-if="
            [
              'BTINFCU4',
              'BTINFMU4',
              'BTSUPCU4',
              'BTSUPLU4',
              'HTACU5',
              'HTACUPM5',
              'HTALU5',
              'HTALUPM5',
            ].includes(editedForm.detaillesContrat.optionTarifaire)
          "
        >
          <v-text-field
            required
            outlined
            type="number"
            v-model="editedForm.detaillesFournisseur.coefHPB"
            label="Coefficient (C-HPB)"
            step="0.001"
            min="0"
          ></v-text-field>
        </v-col>
        <!-- Coefficient (C-HCB) -->
        <v-col
          sm="6"
          md="4"
          lg="3"
          v-if="
            [
              'BTINFCU4',
              'BTINFMU4',
              'BTSUPCU4',
              'BTSUPLU4',
              'HTACU5',
              'HTACUPM5',
              'HTALU5',
              'HTALUPM5',
            ].includes(editedForm.detaillesContrat.optionTarifaire)
          "
        >
          <v-text-field
            required
            outlined
            type="number"
            v-model="editedForm.detaillesFournisseur.coefHCB"
            label="Coefficient (C-HCB)"
            step="0.001"
            min="0"
          ></v-text-field>
        </v-col>

        <!-- Coefficient (C-Base) -->
        <v-col
          sm="6"
          md="4"
          lg="3"
          v-if="
            ['BTINFCUST', 'BTINFLU'].includes(
              editedForm.detaillesContrat.optionTarifaire
            )
          "
        >
          <v-text-field
            required
            outlined
            type="number"
            v-model="editedForm.detaillesFournisseur.coefBase"
            label="Coefficient (C-Base)"
            step="0.001"
            min="0"
          ></v-text-field>
        </v-col>
      </v-row>

      <!-- Coûts Option Tarifaire / typeCapa == 'capa' -->
      <v-row v-if="editedForm.detaillesFournisseur.optionCapacite == 'capa'">
        <!-- Coût (C-P) -->
        <v-col
          sm="6"
          md="4"
          lg="3"
          v-if="
            ['HTACU5', 'HTACUPM5', 'HTALU5', 'HTALUPM5'].includes(
              editedForm.detaillesContrat.optionTarifaire
            )
          "
        >
          <v-text-field
            required
            outlined
            type="number"
            v-model="editedForm.detaillesFournisseur.cout_c_P"
            label="Coût (C-P)"
            step="0.001"
            min="0"
          ></v-text-field>
        </v-col>

        <!-- Coût (C-HP) -->
        <v-col
          sm="6"
          md="4"
          lg="3"
          v-if="
            ['BTINFMUDT'].includes(editedForm.detaillesContrat.optionTarifaire)
          "
        >
          <v-text-field
            required
            outlined
            type="number"
            v-model="editedForm.detaillesFournisseur.cout_c_HP"
            label="Coût (C-HP)"
            step="0.001"
            min="0"
          ></v-text-field>
        </v-col>

        <!-- Coût (C-HC) -->
        <v-col
          sm="6"
          md="4"
          lg="3"
          v-if="
            ['BTINFMUDT'].includes(editedForm.detaillesContrat.optionTarifaire)
          "
        >
          <v-text-field
            required
            outlined
            type="number"
            v-model="editedForm.detaillesFournisseur.cout_c_HC"
            label="Coût (C-HC)"
            step="0.001"
            min="0"
          ></v-text-field>
        </v-col>

        <!-- Coût (C-HPH,C-HCH,C-HPB,C-HPC) -->
        <!-- Coût (C-HPH) -->
        <v-col
          sm="6"
          md="4"
          lg="3"
          v-if="
            [
              'BTINFCU4',
              'BTINFMU4',
              'BTSUPCU4',
              'BTSUPLU4',
              'HTACU5',
              'HTACUPM5',
              'HTALU5',
              'HTALUPM5',
            ].includes(editedForm.detaillesContrat.optionTarifaire)
          "
        >
          <v-text-field
            required
            outlined
            type="number"
            v-model="editedForm.detaillesFournisseur.cout_c_HPH"
            label="Coût (C-HPH)"
            step="0.001"
            min="0"
          ></v-text-field>
        </v-col>

        <!-- Coût (C-HCH) -->
        <v-col
          sm="6"
          md="4"
          lg="3"
          v-if="
            [
              'BTSUPCU4',
              'BTSUPLU4',
              'HTACU5',
              'HTACUPM5',
              'HTALU5',
              'HTALUPM5',
            ].includes(editedForm.detaillesContrat.optionTarifaire)
          "
        >
          <v-text-field
            required
            outlined
            type="number"
            v-model="editedForm.detaillesFournisseur.cout_c_HCH"
            label="Coût (C-HCH)"
            step="0.001"
            min="0"
          ></v-text-field>
        </v-col>

        <!-- Coût (C-HPB) -->
        <v-col
          sm="6"
          md="4"
          lg="3"
          v-if="
            [
              'BTSUPCU4',
              'BTSUPLU4',
              'HTACU5',
              'HTACUPM5',
              'HTALU5',
              'HTALUPM5',
            ].includes(editedForm.detaillesContrat.optionTarifaire)
          "
        >
          <v-text-field
            required
            outlined
            type="number"
            v-model="editedForm.detaillesFournisseur.cout_c_HPB"
            label="Coût (C-HPB)"
            step="0.001"
            min="0"
          ></v-text-field>
        </v-col>
        <!-- Coût (C-HCB) -->
        <v-col
          sm="6"
          md="4"
          lg="3"
          v-if="
            [
              'BTSUPCU4',
              'BTSUPLU4',
              'HTACU5',
              'HTACUPM5',
              'HTALU5',
              'HTALUPM5',
            ].includes(editedForm.detaillesContrat.optionTarifaire)
          "
        >
          <v-text-field
            required
            outlined
            type="number"
            v-model="editedForm.detaillesFournisseur.cout_c_HCB"
            label="Coût (C-HCB)"
            step="0.001"
            min="0"
          ></v-text-field>
        </v-col>

        <!-- Coût (C-Base) -->
        <v-col
          sm="6"
          md="4"
          lg="3"
          v-if="
            ['BTINFCUST', 'BTINFLU'].includes(
              editedForm.detaillesContrat.optionTarifaire
            )
          "
        >
          <v-text-field
            required
            outlined
            type="number"
            v-model="editedForm.detaillesFournisseur.cout_c_Base"
            label="Coût (C-Base)"
            step="0.001"
            min="0"
          ></v-text-field>
        </v-col>
      </v-row>

      <v-row>
        <!-- Certificats d’économies d’énergie -->
        <v-col sm="6" md="4" lg="3">
          <v-text-field
            required
            outlined
            type="number"
            v-model="editedForm.detaillesFournisseur.CEE"
            label="CEE"
            step="0.001"
            min="0"
          ></v-text-field>
        </v-col>

        <v-col sm="6" md="4" lg="3">
          <v-text-field
            required
            outlined
            type="number"
            v-model="editedForm.detaillesFournisseur.ABO"
            label="ABO (par mois)"
            step="0.001"
            min="0"
          ></v-text-field>
        </v-col>
        <v-col sm="6" md="4" lg="3">
          <v-text-field
            required
            outlined
            type="number"
            v-model="editedForm.detaillesFournisseur.nbrMois"
            label="DUREE (N° mois)"
            step="1"
            min="0"
          ></v-text-field>
        </v-col>
      </v-row>
    </template>
    <v-row class="mb-12">
      <v-spacer></v-spacer>
      <v-col
        xs="12"
        sm="6"
        md="3"
        lg="3"
        class="d-flex justify-lg-end justify-md-end justify-sm-center justify-xs-center"
      >
        <v-btn
          depressed
          elevation="1"
          large
          color="primary"
          style="textTransform: none"
          class="ma-auto"
          width="90%"
          type="submit"
          :loading="isLoading"
        >
          Calculer
        </v-btn>
      </v-col>
      <v-col
        xs="12"
        sm="6"
        md="3"
        lg="3"
        class="d-flex justify-lg-end justify-md-end justify-sm-center justify-xs-center"
      >
        <v-btn
          @click="resetForm"
          depressed
          style="textTransform: none"
          large
          elevation="1"
          class="ma-auto"
          width="90%"
          >Réinitialiser</v-btn
        >
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import moment from 'moment'

import axios from 'axios'
import dialogeModal from '../ui/dialogeModal.vue'
export default {
  
  components: {
    dialogeModal,
  },
  emits: ['reset-forms', 'ischangeCompteurValue'],
  props: { listCompteurs: Array, isLoading: Boolean },
 
  data() {
    return {
      dateDebutContratFormatted: new Date().toISOString().substr(0, 10).split('-').reverse().join('/'),
      dateFinContratFormatted: new Date().toISOString().substr(0, 10).split('-').reverse().join('/'),
      
      minDebutContrat: new Date().toISOString().substr(0, 10),
      minDebutFinContrat:new Date().toISOString().substr(0, 10),
      maxDateFinContrat: new Date(new Date().getFullYear() + 5+'-'+12+'-'+31).toISOString().substr(0, 10),
      maxDateDebutContrat: new Date(new Date().getFullYear() + 5+'-'+ 11+'-30').toISOString().substr(0, 10),
      
      dialog: false,
      error: false,
      loading: false,
      messageDialoge: "",
      showModal: null,
      //resultat de api enedis
      resultEnedis: null,
      //variables menus dates
      menuDateDebutContrat: false,
      menuDateFinContrat: false,

      isInfoFurnisseur: false,
      //variables forme
      editedForm: {
        compteur: null,

        detaillesContrat: {
          optionTarifaire: null,
          dateDebutContrat: null,
          dateFinContrat: null,
          marge: null,
          puissanceSouscrite: null,
          kvaBase:'',
          kvaHp: '',
          kvaHc: '',
          kvaHph: '',
          kvaHch: '',
          kvaHpe: '',
          kvaHce: '',
          kvaPte: '',
          
          fournisseurActuel: null,
          consoP: 0,
          consoAHP: 0,
          consoAHC: 0,
          consoAHPH: 0,
          consoAHCH: 0,
          consoAHPB: 0,
          consoAHCB: 0,
          consoA: 0,
        },

        detaillesFournisseur: {
          //variables fournisseur actuel
          founisseur: null,
          optionCapacite: null,
          //
          coutP: 0,
          coutHP: 0,
          coutHC: 0,
          coutHPH: 0,
          coutHCH: 0,
          coutHPB: 0,
          coutHCB: 0,
          coutBase: 0,
          //0
          coefP: 0,
          coefHP: 0,
          coefHC: 0,
          coefHPH: 0,
          coefHCH: 0,
          coefHPB: 0,
          coefHCB: 0,
          coefBase: 0,
          //capa
          cout_c_P: 0,
          cout_c_HP: 0,
          cout_c_HC: 0,
          cout_c_HPH: 0,
          cout_c_HCH: 0,
          cout_c_HPB: 0,
          cout_c_HCB: 0,
          cout_c_Base: 0,

          CEE: null,
          ABO: null,
          nbrMois: 0,
        },
      },

      defaultForm: {
        compteur: null,

        detaillesContrat: {
          optionTarifaire: null,
          dateDebutContrat: null,
          dateFinContrat: null,
          marge: null,
          puissanceSouscrite: null,
          fournisseurActuel: null,
          consoP: 0,
          consoAHP: 0,
          consoAHC: 0,
          consoAHPH: 0,
          consoAHCH: 0,
          consoAHPB: 0,
          consoAHCB: 0,
          consoA: 0,
        },

        detaillesFournisseur: {
          //variables fournisseur actuel
          founisseur: null,
          optionCapacite: null,
          //
          coutP: 0,
          coutHP: 0,
          coutHC: 0,
          coutHPH: 0,
          coutHCH: 0,
          coutHPB: 0,
          coutHCB: 0,
          coutBase: 0,
          //coef
          coefP: null,
          coefHP: null,
          coefHC: null,
          coefHPH: null,
          coefHCH: null,
          coefHPB: null,
          coefHCB: null,
          coefBase: null,
          //capa
          cout_c_P: null,
          cout_c_HP: null,
          cout_c_HC: null,
          cout_c_HPH: null,
          cout_c_HCH: null,
          cout_c_HPB: null,
          cout_c_HCB: null,
          cout_c_Base: null,

          CEE: null,
          ABO: null,
          nbrMois: 0,
        },
      },

      optionsPuissance: [3, 6, 9, 12, 15, 18, 24, 30, 36],

      optionsTarifaires: [
        'BTINFCU4',
        'BTINFCUST',
        'BTINFMU4',
        'BTINFMUDT',
        'BTINFLU',
        'BTSUPCU4',
        'BTSUPLU4',
        'HTACU5',
        'HTACUPM5',
        'HTALU5',
        'HTALUPM5',
      ],
      optionsFournisseurs: [
          'Alpiq',
          'Alterna',
          'Antargaz',
          'Axpo',
          'BARRY',
          'BULB',
          'BUTAGAZ',
          'CDISCOUNT',
          'Dyneff',
          'Edenkia',
          'EDF',
          'EDS',
          'EDSB',
          'Ekwateur',
          'Elecocite',
          'Electricite_de_provence',
          'Electricite_de_savoie',
          'Electricite_de_Strasbourg',
          'ELMY',
          'Enalp',
          'Endesa',
          'Enercoop',
          'Energem',
          'ENERGIE_DICI',
          'Engie',
          'ENI',
          'Enovos',
          'Gaz_de_Bordeaux',
          'GazelEnergie',
          'Gazprom',
          'GEDIA',
          'GEG',
          'GREEN_YELLOW',
          'HAPPE',
          'IBERDROLA',
          'ILEK',
          'la_bellenergie',
          'LECLERCQ',
          'LLUM',
          'LUCIA',
          'MEGA',
          'MET-France',
          'mint-energie',
          'OHM',
          'OVO',
          'Picoty',
          'PLUM',
          'Primeo',
          'Proxelia',
          'Redeéo',
          'SaveEnergies',
          'Selia',
          'SEFE',
          'SOWEE',
          'Synelva',
          'TDE',
          'Urban_Solar',
          'Vattenfall',
          'Volterres',
          'Wekiwi',
          'yeli-xs',
      ],
      //regles de validation forme propositions
      required(fieldName) {
        
        return (value) => !!value || value == 0 || `Veuillez saisir ${fieldName}`
      },
      
      rules: {margeMaxMin: v => (v<=25 && v>=2) || 'La valeur de marge doit être comprise entre 2 et 25.',}
    }
  },
  methods: {
    dateFormatSlach(date){
      return date.split('-').reverse().join('/')
    },
    dateFormatMinus(date){
      return date.split('/').reverse().join('-')
    },
    isValidDate(formatDate, minDate, maxDate) {
      return (date) => {
        var tmpdate = this.dateFormatMinus(date)
        //vérifie si la valeur sasie n'est pas vide
        if (!date) {
          return 'Veuillez saisir une date.'
        }
        
        //vérifie si la date est superieure à la minDate
        
        if (moment(tmpdate).isBefore(minDate)) {
          return `La date doit être supérieure  à ${this.dateFormatSlach(minDate)}.`
        }

        //vérifie si la date est inferieure à maxDate
        if (moment(tmpdate).isAfter(maxDate)) {
          return `La date limite de fin de contrat est: ${this.dateFormatSlach(maxDate)}.`
        }
        return true
      }
    },
    handleBlurDateDebutContrat(){
      this.editedForm.detaillesContrat.dateDebutContrat = this.dateFormatMinus(this.dateDebutContratFormatted)
      var dateDebut= new Date(this.editedForm.detaillesContrat.dateDebutContrat)
      this.minDebutFinContrat =new Date(dateDebut.setMonth(dateDebut.getMonth()+1)).toISOString().substr(0, 10)
    },
    handleBlurDateFinContrat(){
      this.editedForm.detaillesContrat.dateFinContrat = this.dateFormatMinus(this.dateFinContratFormatted)
      
    },
    toggleFormeFournisseur() {
      this.isInfoFurnisseur = !this.isInfoFurnisseur
    },

    handleComteurChange() {
      this.$emit('ischangeCompteurValue', this.editedForm.compteur)
      this.getCompteurData(this.editedForm.compteur)
    },
    submitForm() {
      if (!this.$refs.formDetaillesCompteur.validate()) {
        return
      }
      if(moment(this.editedForm.detaillesContrat.dateFinContrat).isBefore(this.editedForm.detaillesContrat.dateDebutContrat)) return
      //execute une fonction de composant pere
      this.$emit('get-propositions', {
        ...this.editedForm,
        isInfoFurnisseur: this.isInfoFurnisseur,
      })
    },
    resetForm() {
      //remplace un objet editedForm par une copie de defaultForm
      this.editedForm = Object.assign({}, this.defaultForm)
      this.$refs.formDetaillesCompteur.resetValidation()
      this.$emit('reset-forms')
    },
    getCompteurData(compteur) {
      this.showModal = true
      this.dialog = true
      this.loading = true
      this.error = false
      var formData = new FormData()
      formData.append('numCompteur', compteur)
      formData.append('token', this.getToken('token'))
      axios({
        url: process.env.VUE_APP_URL_API_CLIENT + 'getToutLesDonneesParCompteurEnedis/',
        method: 'POST',
        data: formData,
      })
        .then((res) => {
          var data = JSON.parse(JSON.stringify(res.data))
          const detaillesContrat = {}
          const detaillesFournisseur = {}
          if(data.mesuresInfo[0].result==='valide'){
              this.resultEnedis = 'FoundMesures'
              if("HP" in data.mesuresInfo[0].donne){
                this.editedForm.detaillesContrat.consoAHP = data.mesuresInfo[0].donne.HP.somme
              }else{
                this.editedForm.detaillesContrat.consoAHP = null
              }
              if("HC" in data.mesuresInfo[0].donne){
                this.editedForm.detaillesContrat.consoAHC = data.mesuresInfo[0].donne.HC.somme
              }else{
                this.editedForm.detaillesContrat.consoAHC = null
              }
              if("HPH" in data.mesuresInfo[0].donne){
                this.editedForm.detaillesContrat.consoAHPH = data.mesuresInfo[0].donne.HPH.somme
              }else{
                this.editedForm.detaillesContrat.consoAHPH = null
              }
              if("HCH" in data.mesuresInfo[0].donne){
                this.editedForm.detaillesContrat.consoAHCH = data.mesuresInfo[0].donne.HCH.somme
              }else{
                this.editedForm.detaillesContrat.consoAHCH = null
              }
              if("HPE" in data.mesuresInfo[0].donne){
                this.editedForm.detaillesContrat.consoAHPB = data.mesuresInfo[0].donne.HPE.somme
              }else{
                this.editedForm.detaillesContrat.consoAHPB = null
              }
              if("HCE" in data.mesuresInfo[0].donne){
                this.editedForm.detaillesContrat.consoAHCB = data.mesuresInfo[0].donne.HCE.somme
              }else{
                this.editedForm.detaillesContrat.consoAHCB = null
              }
              if("POINTE" in data.mesuresInfo[0].donne){
                this.editedForm.detaillesContrat.consoP = data.mesuresInfo[0].donne.POINTE.somme
              }else{
                this.editedForm.detaillesContrat.consoP = null
              }
              if("PTE" in data.mesuresInfo[0].donne){
                this.editedForm.detaillesContrat.consoP = data.mesuresInfo[0].donne.PTE.somme
              }else{
                this.editedForm.detaillesContrat.consoP = null
              }
              if("BASE" in data.mesuresInfo[0].donne){
                this.editedForm.detaillesContrat.consoA = data.mesuresInfo[0].donne.BASE.somme
              }else{
                this.editedForm.detaillesContrat.consoA = null
              }
          }else{
            this.resultEnedis = ''
            this.editedForm.detaillesContrat.consoAHP = null
            this.editedForm.detaillesContrat.consoAHC = null
            this.editedForm.detaillesContrat.consoAHPH = null
            this.editedForm.detaillesContrat.consoAHCH = null
            this.editedForm.detaillesContrat.consoAHPB = null
            this.editedForm.detaillesContrat.consoAHCB = null
            this.editedForm.detaillesContrat.consoP = null
            this.editedForm.detaillesContrat.consoP = null
            this.editedForm.detaillesContrat.consoA = null
          }
          if(data.donneTechniqueInfo[0].result==='valide'){
            if(data.donneTechniqueInfo[0].data.typeCompteur != "" && data.donneTechniqueInfo[0].data.kva != "" ){
              this.resultEnedis = 'FoundTechnique'
              this.editedForm.detaillesContrat.optionTarifaire = data.donneTechniqueInfo[0].data.typeCompteur
              this.editedForm.detaillesContrat.puissanceSouscrite = data.donneTechniqueInfo[0].data.kva
            }else{
              this.resultEnedis = ''
              this.editedForm.detaillesContrat.optionTarifaire = null
              this.editedForm.detaillesContrat.puissanceSouscrite = null
            }
            // get tout les kva
            
            if("BASE" in data.donneTechniqueInfo[0].data){
              this.editedForm.detaillesContrat.kvaBase = data.donneTechniqueInfo[0].data.BASE
            }
            if("HP" in data.donneTechniqueInfo[0].data){
              this.editedForm.detaillesContrat.kvaHp = data.donneTechniqueInfo[0].data.HP
            }
            if("HC" in data.donneTechniqueInfo[0].data){
              this.editedForm.detaillesContrat.kvaHc = data.donneTechniqueInfo[0].data.HC
            }
            if("HCE" in data.donneTechniqueInfo[0].data){
              this.editedForm.detaillesContrat.kvaHce = data.donneTechniqueInfo[0].data.HCE
            }
            if("HPE" in data.donneTechniqueInfo[0].data){
              this.editedForm.detaillesContrat.kvaHpe = data.donneTechniqueInfo[0].data.HPE
            }
            if("HCH" in data.donneTechniqueInfo[0].data){
              this.editedForm.detaillesContrat.kvaHch = data.donneTechniqueInfo[0].data.HCH
            }
            if("HPH" in data.donneTechniqueInfo[0].data){
              this.editedForm.detaillesContrat.kvaHph = data.donneTechniqueInfo[0].data.HPH
            }
            if("PTE" in data.donneTechniqueInfo[0].data){
              this.editedForm.detaillesContrat.kvaPte = data.donneTechniqueInfo[0].data.PTE
            }
          }
          if (data[0].res === '1') {
            this.editedForm.detaillesContrat.dateDebutContrat =
              data[0].dateDebut
            this.editedForm.detaillesContrat.dateFinContrat = data[0].dateFin
            
            this.dateDebutContratFormatted = this.dateFormatSlach(this.editedForm.detaillesContrat.dateDebutContrat)
            this.dateFinContratFormatted = this.dateFormatSlach(this.editedForm.detaillesContrat.dateFinContrat)
            this.editedForm.detaillesContrat.marge = data[0].marge
          }
          if (data[1].res === '1') {
            this.editedForm.detaillesFournisseur.fournisseur =
              data[1].fornisseur

            this.editedForm.detaillesFournisseur.coutP = data[1].pointe
            this.editedForm.detaillesFournisseur.coutHP = data[1].hp
            this.editedForm.detaillesFournisseur.coutHC = data[1].hc
            this.editedForm.detaillesFournisseur.coutHPH = data[1].hph
            this.editedForm.detaillesFournisseur.coutHCH = data[1].hch
            this.editedForm.detaillesFournisseur.coutHPB = data[1].hpe
            this.editedForm.detaillesFournisseur.coutHCB = data[1].hce
            this.editedForm.detaillesFournisseur.coutBase = data[1].base
            //coef des fornisseur acctuelle
            this.editedForm.detaillesFournisseur.cout_c_P = data[1].capaP
            this.editedForm.detaillesFournisseur.cout_c_HP = data[1].capaHp
            this.editedForm.detaillesFournisseur.cout_c_HC = data[1].capaHc
            this.editedForm.detaillesFournisseur.cout_c_HPH = data[1].capaHph
            this.editedForm.detaillesFournisseur.cout_c_HCH = data[1].capaHch
            this.editedForm.detaillesFournisseur.cout_c_HPB = data[1].capaHpe
            this.editedForm.detaillesFournisseur.cout_c_HCB = data[1].capaHce
            this.editedForm.detaillesFournisseur.cout_c_Base = data[1].capaBase
            this.editedForm.detaillesFournisseur.optionCapacite = 'capa'
            this.editedForm.detaillesFournisseur.ABO = data[1].Abo
            // fornisseur Actuelle
            this.editedForm.detaillesFournisseur.CEE = data[1].cee
            this.editedForm.detaillesFournisseur.nbrMois = 0
            
          }

          this.showModal = false
          
          this.dialog = false
          this.loading = false
          this.error = false
        })
        .catch((error) => {
          this.dialog = false
          this.loading = false
          this.error = false
          console.log(error)
          this.showModal = false
        })
    },
  },
  computed: {
    isDisabledTechnique() {
      if (
        this.resultEnedis === 'FoundTechnique' ||
        this.resultEnedis === 'FoundMesures'
      ) {
        return true
      } else {
        return false
      }
    },
    isDisabledMesures() {
      if (this.resultEnedis === 'FoundMesures') {
        return true
      } else {
        return false
      }
    },
  },
}
</script>
<style scoped lang="scss">
/* The Modal (background) */
.modall {
  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
  padding-top: 100px; /* Location of the box */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
}

/* Modal Content */
.modall-content {
  background-color: #fefefe;
  margin: auto;
  padding: 20px;
  border: 1px solid #888;
  width: 50%;
}
.loader {
  margin: auto;
  border: 16px solid #f3f3f3;
  border-radius: 50%;
  border-top: 16px solid #3498db;
  width: 120px;
  height: 120px;
  -webkit-animation: spin 2s linear infinite; /* Safari */
  animation: spin 2s linear infinite;
}

/* Safari */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
