import Vue from 'vue'
import VueRouter from 'vue-router'

import Login from '../views/Login'
import listeValidationCotation from '../views/demande-cotation/listeValidationCotation.vue'
import DemandeDeCotation from '../views/demande-cotation/DemandeDeCotation.vue'
import mesDemandesDeCotation from '../views/demande-cotation/listeMesDemandesDeCotation.vue'

import demandeDeCotationElecPro from '../views/demande-cotation-pro/demandeCotationElecPro'
import NewdemandeDeCotationElecPro from '../views/demande-cotation-pro/newDemandeDeCotationElec.vue'


import demandeDeCotationGazPro from '../views/demande-cotation-pro/demandeDeCotationGazPro'
import NewDemandeDeCotationGazPro from '../views/demande-cotation-pro/newDemandeDeCotationGazPro'

import premioContrat from '../views/edition-contrat/primeoEnergie.vue'
import engiePro from '../views/edition-contrat/engiePro.vue'
import gazelContrat from '../views/edition-contrat/gazelContrat.vue'
import Totale from '../views/edition-contrat/tataleContrat.vue'
import TotalHorizon from '../views/edition-contrat/totalHorizon.vue'
import edsContrat from '../views/edition-contrat/EDS.vue'
import offresExternesAdmins from '../views/nouvelles-offres/offresExternesAdmins.vue'
import ajouterNouvelleOffre from "../views/nouvelles-offres/ajouterNouvelleOffre.vue"
import offresExternes from "../views/nouvelles-offres/offresExternes.vue"
import controleSocietes from '../views/societe/controleSocietes'
import profileSociete from '../views/societe/societeProfil'
import historiqueCotation from '../views/societe/historiqueCotation.vue'
import newHistoriqueCotation from '../views/societe/new-historiqueCotation.vue'


import mesSocietes from '../views/societe/mesSocietes'
import monProfileSociete from '../views/societe/monProfileSociete'
import AjouterVentePro from '../views/vente-pro/AjouterVentePro'
import mesVentePro from '../views/vente-pro/mesVentePro'
import mesVenteProMulti from '../views/vente-pro/mesVenteProMulti.vue'
import controleVenteProMulti from '../views/vente-pro/controleVenteProMulti.vue'

import AutorisationDeCollectionDeDonnees from '../views/AutorisationDeCollectionDeDonnees.vue'
import ElecProfessionnels from '../views/ElecProfessionnels.vue'
import ValidationDuCotation from '../views/ValidationDuCotation'
import TestView from '../views/TestView'
import mesDemandeAcd from '../views/mesDemandeAcd'
import NouvelleOportunite from '../views/NouvelleOpportunite'
import nextTest from '../views/nextJsConsumeApi.vue'
import Charts from '../views/charts/ChartsPage.vue'

// les equipe

import ajouterEquipe from '../views/equipe/ajouterEquipe.vue'
import ajouterEquipeAdv from '../views/equipe/ajouterEquipeAdv.vue'
import demandeCreationEquipeAdmin from '../views/equipe/listeDemandeDeCreationequipeAdmin.vue'
import addRapideMessage from '../views/admin/addRapideMessage.vue'
import listeEquipeAdmin from '../views/equipe/listeEquipeAdmin.vue'
import listeEquipeAdv from '../views/equipe/listeEquipeAdv.vue'

import Vendeurs from '../views/admin/Vendeurs'
import VendeursAvd from '../views/admin/vendeursAvd'
import VendeursChef from '../views/admin/vendeursChef'

import ajouterUneVenteDansRapport from '../views/rapportVente/ajouterVente.vue'
import RapportDeVente from '../views/vente-pro/rapportDeVente.vue'
import ajouterVenteProMulti from '../views/vente-pro/ajouterVenteProMulti.vue'

import historiqueCotationGaz from '../views/societe/historiqueCotationGaz.vue'
import newHistoriqueCotationGaz from '../views/societe/new-historiqueCotationGaz.vue'

Vue.use(VueRouter)
const routes = [

  {
    path: '/historiqueCotationGaz',
    name: 'historiqueCotationGaz',
    component: historiqueCotationGaz,
  },
  {
    path: '/new-HistoriqueCotationGaz',
    name: 'newHistoriqueCotationGaz',
    component: newHistoriqueCotationGaz,
    meta:{
      requiresAuth:true,
      roles:['admin', 'superadmin', 'pro','pro-part','Chef équipe', 'ADV','Vendeur']
    }    
  },
  {
    path: '/ajouterVenteProMulti',
    name: 'ajouterVenteProMulti',
    component: ajouterVenteProMulti,
  },
  {
    path: '/rapport-vente',
    name: 'RapportDeVente',
    component: RapportDeVente,
  },
  {
    path: '/ajouterUneVenteDansRapport',
    name: 'ajouterUneVenteDansRapport',
    component: ajouterUneVenteDansRapport,
  },
  {
    path: '/controle-alerte',
    name: 'addRapideMessage',
    component: addRapideMessage,
  },
  {
    path: '/listeEquipeAdmin',
    name: 'listeEquipeAdmin',
    component: listeEquipeAdmin,
  },
  {
    path: '/listeEquipeAdv',
    name: 'listeEquipeAdv',
    component: listeEquipeAdv,
  },
  {
    path: '/demandeCreationEquipeAdmin',
    name: 'demandeCreationEquipeAdmin',
    component: demandeCreationEquipeAdmin,
  },
  {
    path: '/ajouterEquipe',
    name: 'ajouterEquipe',
    component: ajouterEquipe,
  },
  {
    path: '/ajouterEquipeAdv',
    name: 'ajouterEquipeAdv',
    component: ajouterEquipeAdv,
  },
  {
    path: '/VendeursAvd',
    name: 'VendeursAvd',
    component: VendeursAvd,
  },
  {
    path: '/VendeursChef',
    name: 'VendeursChef',
    component: VendeursChef,
  },
  {
    path:'/graphiques',
    name:'Charts',
    component:Charts

  },
  {
    path:"/mes-offres-externes",
    name:'offresExternes',
    component:offresExternes
  },
  {
    path:"/ajouter-nouvelle-offre",
    name:'ajouterNouvelleOffre',
    component:ajouterNouvelleOffre
  },
  {
    path: '/mes-profils-societes',
    name: 'monProfileSociete',
    component: monProfileSociete,
  },
  {
    path: '/mes-ventes-pro',
    name: 'listeMesVentesPro',
    component: mesVenteProMulti,
  },
  {
    path: '/mes-demandes-cotation',
    name: 'mesDemandesDeCotation',
    component: mesDemandesDeCotation,
  },
  {
    path: '/mes-societes',
    name: 'mesSocietes',
    component: mesSocietes,
  },
  {
    path: '/historiqueCotation',
    name: 'historiqueCotation',
    component: historiqueCotation,
  },
  {
    path: '/new-historiqueCotation',
    name: 'newHistoriqueCotation',
    component: newHistoriqueCotation,
    meta:{
      requiresAuth:true,
      roles:['admin', 'superadmin', 'pro','pro-part','Chef équipe', 'ADV','Vendeur']
    }
  },
  {
    path: '/offres-externes-admins',
    name: 'offresExternesAdmins',
    component: offresExternesAdmins,
  },
  {
    path: '/validation-cotation',
    name: 'listeValidationCotation',
    component: listeValidationCotation,
  },
  {
    path: '/les-profils-societes',
    name: 'profileSociete',
    component: profileSociete,
  },
  {
    path: '/controle-societes',
    name: 'controleSocietes',
    component: controleSocietes,
  },
  {
    path: '/controle-ventes-pro',
    name: 'ControleVentesPro',
    component: controleVenteProMulti,
  },
  {
    path: '/nextTest',
    name: 'nextTest',
    component: nextTest,
  },
  {
    path: '/old-demande-cotation-elec-pro',
    name: 'demandeDeCotationElecPro',
    component: demandeDeCotationElecPro,
  },
  {
    path: '/demande-cotation-elec-pro',
    name: 'newDemandeDeCotationElecPro',
    component: NewdemandeDeCotationElecPro,
  },
  {
    path: '/old-demande-cotation-gaz-pro',
    name: 'demandeDeCotationGazPro',
    component: demandeDeCotationGazPro,
  },
  
  {
    path: '/demande-cotation-gaz-pro',
    name: 'newDemandeDeCotationGazPro',
    component: NewDemandeDeCotationGazPro
  },
  {
    path: '/TotaleContrat',
    name: 'Totale',
    component: Totale,
  },
  {
    path: '/TotalHorizon',
    name: 'TotalHorizon',
    component: TotalHorizon,
  },
  {
    path: '/edsContrat',
    name: 'edsContrat',
    component: edsContrat,
  },
  {
    path: '/premioContrat',
    name: 'premioContrat',
    component: premioContrat,
  },
  {
    path: '/engieProContrat',
    name: 'engieProContrat',
    component: engiePro,
  },
  {
    path: '/gazelContrat',
    name: 'gazelContrat',
    component: gazelContrat,
  },
  {
    path: '/MesVentePro',
    name: 'MesVentePro',
    component: mesVentePro,
  },
  {
    path: '/ajouter-vente-pro',
    name: 'AjouterVentePro',
    component: AjouterVentePro,
  },
  {
    path: '/mesDemandeAcd',
    name: 'mesDemandeAcd',
    component: mesDemandeAcd,
  },
  {
    path: '/nouvelle-opportunite',
    name: 'NouvelleOportunite',
    component: NouvelleOportunite,
  },
  {
    path: '/nouvelle-opportunite/autorisation-collecte-donnees',
    name: 'AutorisationDeCollectionDeDonnees',
    component: AutorisationDeCollectionDeDonnees,
  },

  {
    path: '/nouvelle-opportunite/demande-cotation',
    name: 'DemandeDeCotation',
    component: DemandeDeCotation,
  },
  {
    path: '/test-view',
    name: 'test',
    component: TestView,
  },
  {
    path: '/vendeurs',
    name: 'Vendeurs',
    component: Vendeurs,
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
  },
  {
    path: '/elec-professionnels',
    name: 'ElecProfessionnels',
    component: ElecProfessionnels,
  },
  {
    path: '/ValidationDuCotation',
    name: 'ValidationDuCotation',
    component: ValidationDuCotation,
  },
  {
    path: "*",
    name: 'Login',
    component: Login 
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
})

export default router
